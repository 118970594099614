.slowly-start {
	opacity: 0;


	animation: slowlyStart .5s 1s 1 ease-in-out;
	animation-fill-mode: forwards;
}

.slowly-start_delay-1s {
	transition-delay: 1s;
}

.slowly-start_delay-2s {
	transition-delay: 2s;
}

@keyframes slowlyStart {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}