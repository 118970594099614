/*Global styles ---------------------------------*/

/*limited reset*/
html,
body,
div,
section,
article,
aside,
header,
hgroup,
footer,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
time,
span,
em,
strong,
img,
ol,
ul,
li,
figure,
canvas,
video,
th,
td,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

.clear {
  clear: both;
}

body {
  font: @greate-font;
  color: @font-color;
  left: 0 !important;

  min-width: 320px;
  overflow-x: hidden;

  background-color: #e6e7e8;

  .all-content {
    width: 100%;

    position: relative;
    margin: 0 auto;
  }

  counter-reset: section;
}

.body {
  position: relative;
}

p {
  margin-bottom: 10px;
}

a {
  color: #2289a0;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input,
textarea,
select,
button {
  font: @greate-font;
}

.btn-default {
  padding: 7px 25px;
  border-radius: 5px;
  border: none;

  display: inline-block;

  color: #fff;
  text-decoration: none;

  cursor: pointer;
  outline: none;

  transition: 0.5s;

  position: relative;
}

.btn-default:hover,
.btn-default:focus {
  transform: scale(1.05);

  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  );
}

.btn-default:active {
  transform: scale(0.95);
}

.btn {
  background-color: #ed1c24;
}

.btn-active {
  background-color: #2289a0;
}

.btn-info {
  background-color: #009c41;
}

.btn-light {
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 6px 0 rgba(51, 51, 51, 0.34);

  display: inline-block;
  background-color: #f6f6f6;
  background-image: linear-gradient(
    to bottom,
    rgba(256, 256, 256, 0),
    rgba(0, 0, 0, 0.1)
  );

  color: #000;
  text-decoration: none;

  cursor: pointer;
  outline: none;

  transition: 0.5s;
}

.btn-light:hover,
.btn-light:focus {
  transform: scale(1.05);
}

.btn-light:active {
  transform: scale(0.95);
}

.btn-long {
  width: 357px;
  max-width: 100%;
}

.btn_order {
  padding-left: 60px;
}

.btn_order::before {
  content: '';
  position: absolute;
  top: 17px;
  left: 25px;

  height: 2px;
  width: 22px;

  background-color: #fff;

  margin-right: 10px;
}

.btn_order::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 31px;

  display: block;

  height: 16px;
  width: 16px;
  box-sizing: border-box;

  border: 2px solid #fff;
  border-left-color: transparent;
  border-top-color: transparent;

  transform: rotate(-45deg);
}

.btn_order:hover::before {
  background-color: #13a1ff;
}

.btn_order:hover::after {
  border-right-color: #13a1ff;
  border-bottom-color: #13a1ff;
}

.btn-link {
  background-color: transparent;
  background-image: none;
  background-color: #fff;

  cursor: pointer;
  outline: none;

  border: 1px solid @font-color;
  border-radius: 20px;

  color: @font-color;
  font-size: 14px;

  transition: 0.8s;

  padding: 7px 25px;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn-link:active {
  transform: scale(0.95);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  color: #333;
  font-weight: 300;
  line-height: 1.3;

  margin-bottom: 10px;
}

h1,
.h1 {
  font-size: 22px;
}

.service-page__title h1,
.service-page__title .h1 {
  text-align: left;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 16px;
}

.container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  width: 100%;
}

.h-m0auto {
  margin: 0 auto;
}
.h-mauto {
  margin: auto;
}
.h-mt-40 {
  margin-top: -40px;
}
.h-mt-20 {
  margin-top: -20px !important;
}
.h-mt-5 {
  margin-top: -5px;
}
.h-mt0 {
  margin-top: 0 !important;
}
.h-mt6 {
  margin-top: 6px !important;
}
.h-mt10 {
  margin-top: 10px;
}
.h-mt15 {
  margin-top: 15px;
}
.h-mt20 {
  margin-top: 20px !important;
}
.h-mt25 {
  margin-top: 25px;
}
.h-mt30 {
  margin-top: 30px;
}
.h-mt40 {
  margin-top: 40px !important;
}
.h-mt45 {
  margin-top: 45px;
}
.h-mt50 {
  margin-top: 50px;
}
.h-mt65 {
  margin-top: 65px !important;
}
.h-mt80 {
  margin-top: 80px !important;
}
.h-mb-10 {
  margin-bottom: -10px !important;
}
.h-mb0 {
  margin-bottom: 0px !important;
}
.h-mb5 {
  margin-bottom: 5px;
}
.h-mb10 {
  margin-bottom: 10px;
}
.h-mb15 {
  margin-bottom: 15px;
}
.h-mb20 {
  margin-bottom: 20px;
}
.h-mb25 {
  margin-bottom: 25px;
}
.h-mb30 {
  margin-bottom: 30px;
}
.h-mb35 {
  margin-bottom: 35px;
}
.h-mb40 {
  margin-bottom: 40px !important;
}
.h-mb80 {
  margin-bottom: 80px !important;
}
.h-mb120 {
  margin-bottom: 120px !important;
}
.h-mb130 {
  margin-bottom: 130px !important;
}
.h-ml0 {
  margin-left: 0px !important;
}
.h-ml10 {
  margin-left: 10px;
}
.h-ml50 {
  margin-left: 50px;
}
.h-mr20 {
  margin-right: 20px;
}

.h-p20 {
  padding: 20px;
}
.h-pl0 {
  padding-left: 0 !important;
}
.h-pb0 {
  padding-bottom: 0 !important;
}
.h-pt0 {
  padding-top: 0px !important;
}
.h-pt10 {
  padding-top: 10px !important;
}
.h-pt20 {
  padding-top: 20px;
}
.h-pt30 {
  padding-top: 30px;
}

.h-pt50 {
  padding-top: 50px !important;
}

.h-pb20 {
  padding-bottom: 20px;
}
.h-pb30 {
  padding-bottom: 30px;
}
.h-pb60 {
  padding-bottom: 60px;
}
.h-pr5 {
  padding-right: 5px;
}
.h-pr20 {
  padding-right: 20px;
}
.h-pl10 {
  padding-left: 10px;
}
.h-pl15 {
  padding-left: 15px;
}
.h-pl30 {
  padding-left: 30px;
}

.f-14 {
  font-size: 14px;
  line-height: 1.15;
}
.f-16 {
  font-size: 16px;
  line-height: 1.4;
}
.f-18 {
  font-size: 18px;
}
.f-22 {
  font-size: 22px;
}
.f-24 {
  font-size: 24px;
}
.f-30 {
  font-size: 30px;
}

.f-left {
  text-align: left !important;
}
.f-right {
  text-align: right;
}
.f-center {
  text-align: center !important;
}
.f-none {
  float: none !important;
}

.f-bold {
  font-weight: bold;
}
.f-medium {
  font-weight: 500;
}

.f-uppercase {
  text-transform: uppercase;
}
.f-underline {
  text-decoration: underline;
}

.f-red {
  color: @red-color !important;
}
.f-yellow {
  color: @bg-btn-color;
}
.f-blue {
  color: #69c;
}
.f-dblue {
  color: #0067c3;
}
.f-white {
  color: @white-color !important;
}
.f-black {
  color: @black-color;
}
.f-light_blue {
  color: #2289a0;
}

.list-unstyled {
  list-style: none;
}

.img-l,
.img-r {
  margin: 5px auto;

  display: block;
}

.border-top-1 {
  border-top: 1px solid #e0e1e2;
}

.d-f {
  display: flex;
}

.d-f-wrap {
  flex-wrap: wrap;
}

.d-f-wrap-relink {
  @media(max-width: 768px) {
    flex-wrap: wrap;
  }
}

@media all and (min-width: 800px) {
  .d-f-desc {
    display: flex;
  }
}

.fl-wr {
  flex-wrap: wrap !important;
}

.fl-center {
  align-items: center;
}

.fl-between {
  justify-content: space-between;
}

.fl-jc-center {
  justify-content: center;
}

.f-white .h-mt-50 {
  margin-top: -50px;
}

header .logo {
  width: 88px;
  height: auto;
  margin: 0 auto;
}

.top-menu {
  background-color: @white-color;
  width: 100%;

  padding-top: 7px;
  padding-bottom: 10px;
}

.top-menu .container {
  position: relative;
}

.logo-block {
  display: none;
}

#navigation {
  display: none;
}

#mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  z-index: 999;

  min-height: 40px;
  width: 100%;

  padding: 5px 8px;

  background-color: #fff;
}

.h-contacts {
  .a-contacts {
    padding-top: 5px;
  }

  & > div:first-child {
    margin-top: -3px;
  }
}

.to-top_btn {
  color: #1c1c1c;

  border: none;
  background-color: transparent;
  background-image: none;

  cursor: pointer;

  outline: none;
}

.to-top_btn::after {
  content: '';

  display: inline-block;
  vertical-align: sub;
  height: 8px;
  width: 8px;

  margin-left: 5px;

  border: 2px solid #1c1c1c;
  border-right-color: transparent;
  border-bottom-color: transparent;

  background-color: transparent;

  transform: rotate(45deg);

  cursor: pointer;
  outline: none;
}

.menu-btn {
  display: flex;
  flex-direction: column;
}

.menu-btn__item {
  background-color: #1c1c1c;

  display: block;
  width: 25px;
  height: 4px;

  border-radius: 2px;
}

.menu-btn__item:not(:last-child) {
  margin-bottom: 3px;
}

.menu-btn_close {
  position: relative;
}

.menu-btn_close .menu-btn__item {
  position: absolute;
  top: 0;
}

.menu-btn_close .menu-btn__item:nth-child(odd) {
  transform: rotate(45deg);
}

.menu-btn_close .menu-btn__item:nth-child(even) {
  transform: rotate(-45deg);
}

.sidr {
  background: @white-color;
  top: 40px;
  padding: 15px;
  border-top: 1px solid #e0e1e2;

  z-index: 102;
}

.a-tel {
  color: #333;
  font-size: 20px;
  text-decoration: none;
}

.a-contacts {
  font-size: 14px;
}

.clean_table {
  p {
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    position: relative;
  }

  .border-r {
    display: block;
    width: 220px;
    padding: 10px;

    text-align: right;
    font-size: 20px;
    font-weight: bold;

    border-right: 7px solid #2289a0;
  }

  span:last-child {
    max-width: 80%;
    padding: 10px;
    position: relative;
    right: 7px;
    border-left: 7px solid #2289a0;
  }
}

.top-img {
  background-color: #5c8392;

  position: relative;
  overflow: hidden;

  @media (min-width: 600px) {
    background-color: #8b94a3;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: -70px;
      left: 0;

      background-image: linear-gradient(
        to left,
        transparent,
        #b6b5b6,
        transparent
      );
      background-position: right 45px bottom 20px;
      background-size: 100% 200px;
      background-repeat: no-repeat;

      transform: rotate(-5deg);

      z-index: 0;
    }
  }

  &_winter {
    background-image: url(/images/bg-banner-winter.jpg);

    &-main {
      height: 100%;
    }
  }
}

.top-img_blue {
  background-color: #5d9cae;

  @media (min-width: 600px) {
    background-color: #5d9cae;

    &::before {
      background-image: linear-gradient(
        to left,
        transparent,
        #99cad8,
        transparent
      );
    }
  }
}

.main-slider {
  position: relative;
  z-index: 9;

  display: flex;
  align-items: flex-end;

  min-height: 210px;

  @media (min-width: 768px) {
    min-height: 265px;
  }

  @media (min-width: 1250px) {
    min-height: 330px;
  }
}

.main-slider_winter {
  @media (min-width: 1250px) {
    min-height: 265px;
  }
}

.main-slider__item {
  text-align: center;
}

.main-slider__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & img {
    opacity: 0.5;
    max-width: 320px;

    @media (min-width: 425px) {
      margin-right: 0 !important;
    }

    @media (min-width: 768px) {
      opacity: 1;
      margin-right: auto !important;
    }

    @media (min-width: 1250px) {
      opacity: 1;
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    position: initial;

    justify-content: center;
    flex-shrink: 0;
    flex-grow: 1;

    min-width: 50%;
  }

  @media (min-width: 1250px) {
  }
}

.top-img_winter .main-slider__img img {
  @media (min-width: 768px) {
    margin-right: 0 !important;
  }
}

.main-slider__desc {
  position: relative;
  z-index: 10;

  color: #fff;

  width: 100%;
  padding-top: 5px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    min-width: 50%;
  }

  @media (min-width: 1250px) {
    padding-top: 0;
  }
}

.top-img_winter .main-slider__desc {
  @media (min-width: 768px) {
    min-width: 52%;
  }
}

.main-slider__desc-small {
  @media (min-width: 1250px) {
    & .main-slider__tit {
      margin-bottom: 20px;
    }

    & .main-slider__t-big {
      margin-bottom: 30px;
    }
  }
}

.main-slider__tit {
  font-size: 25px;
  font-weight: lighter;
  line-height: 1;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 1250px) {
    font-size: 45px;
  }
}

.main-slider__t-big {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;

  @media (min-width: 768px) {
    font-size: 45px;
  }

  @media (min-width: 1250px) {
    font-size: 60px;
  }
}

.content h1,
.content .h1,
.content h2,
.content .h2 {
  text-align: center;
}
.content-block {
  padding: 30px 0 50px;
}

.elem-list {
  display: flex;
  flex-wrap: wrap;

  .elem-list_item {
    background-color: #fff;

    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #eeeff0;

    margin-top: 0;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 100%;
  }
}

.elem-list .btn {
  padding: 7px 10px;
}

.elem-list .btn_order {
  padding-left: 45px;
}

.elem-list .btn_order::before {
  left: 10px;
}

.elem-list .btn_order::after {
  left: 16px;
}

.elem-item__availability {
  margin-bottom: 7px;
  margin-top: 10px;

  font-size: 14px;
}

.elem-item__availability::before {
  content: '';

  height: 25px;
  width: 27px;
  display: inline-block;
  vertical-align: bottom;

  background-image: url(/images/sprite.png);
  background-position: -63px -193px;

  margin-right: 5px;
}

.elem-list_item-name {
  font-size: 1.25em;
  font-weight: normal;

  margin-bottom: 15px;
}

.goods-table .elem-list_item-name {
  font-size: 14px;
}

.elem-list_item-link {
  text-decoration: none;
  color: #333333;
}

.elem-list_item-desc {
  padding: 0 5px 10px;

  border-bottom: 1px solid #ed1c24;

  p {
    font-size: 16px;
  }
}

.desc_bottom {
  margin-top: 30px;
}

.desc_top {
  margin-bottom: 30px;
}

.width50 {
  width: 49%;
}

.h-w80 {
  width: 80%;
}

.h-w100 {
  width: 100%;
}

.h-w135 {

  @media (min-width: 1060px) {
    width: 135px;
  }
}

.h-w170 {
  width: 170px!important;
}

.elem-list_item-price-block {
  padding: 10px 5px;

  p {
    position: relative;
  }

  .elem-list_item-price-sale {
    position: absolute;
    text-decoration: line-through;
    top: -13px;
    left: 43px;
  }

  .checkbox {
    margin-bottom: 25px;

    label {
      color: @link-color;
    }
  }
}

.price__btns {
  text-align: center;
}

.price__btn {
  margin-bottom: 10px;
}

form {
  input,
  textarea {
    border: 1px solid #ccc;
    border-radius: 0.3em;
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box;

    &:hover,
    &:focus {
      outline: none;
      border-color: #2289a0;
    }
  }

  input[type='submit'] {
    width: auto;
  }
}

.form__elem {
  margin-bottom: 10px;
}

.bx-wrapper .bx-viewport {
  display: inline-block;
  background: transparent;
}
.bx-wrapper {
  margin: 0 auto;
  overflow: hidden;
}

.border-bottom_light-gray {
  border-bottom: 1px solid #d8d9db;
}

.copyright {
  color: #666;

  margin-bottom: 0;
}

.footer {
  display: flex;
  flex-direction: column;

  background-color: #2a3233;
  font-family: Roboto Condensed, Roboto, Arial, Helvetica, sans-serif;
  color: #fff;

  padding: 15px 0;
}

.footer a {
  color: #fff;
}

.footer__content:not(:last-child) {
  margin-bottom: 25px;
}

.footer-contacts__item:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;

  border-bottom: 1px solid #999;
  border-image: 1
    linear-gradient(
      to right,
      #999 0,
      #999 90px,
      transparent 0,
      transparent 100%
    );
}

.footer-contacts__item p {
  margin-bottom: 0;
}

.footer-contacts__title {
  font-weight: bold;
  font-size: 1.5em;
}

.footer-contacts__item .footer-contacts__title {
  margin-bottom: 7px;
}

.xs-hidden {
  display: none;
}
.sm-block {
  display: none;
}
.md-block {
  display: none;
}
.lg-block {
  display: none;
}

.station-tipes {
  display: flex;
  flex-wrap: wrap;

  .station-tipes__item {
    max-width: 290px;
    padding: 10px 12px;

    border: 1px solid #710e2d;

    img {
      display: block;
      max-height: 250px;
      margin: auto;
    }
  }

  .station-tipes__item:not(:last-child) {
    margin-right: 10px;
  }
}

.doc-list__item {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
}

.doc-list__item::before {
  content: '';
  height: 16px;
  width: 16px;

  background-image: url(/images/sprite.png);
  background-position: -293px -182px;

  margin-right: 5px;

  flex-shrink: 0;
}

.ul {
  list-style: none;
}

.ul li {
  position: relative;
  padding-left: 26px;
  padding-bottom: 7px;

  overflow: hidden;
}

.ul li::before {
  left: 10px;
  top: 5px;
  content: '';
  position: absolute;

  height: 8px;
  width: 8px;

  background-color: #2289a0;
  border-radius: 50%;
}

.ul-col-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ul-col-2 li {
  width: 49%;
}

.tech-description {
  margin-bottom: 10px;
}

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked,
[type='radio']:not(:checked),
[type='radio']:checked {
  position: absolute;
  left: -9999px;
}
[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label,
[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
  position: relative;
  cursor: pointer;

  padding-left: @size-of-checkbox + 5px;

  min-height: @size-of-checkbox;
  display: flex;
  align-items: center;

  a:visited {
    color: #005eb8;
  }
}
/* checkbox aspect */
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before,
[type='radio']:not(:checked) + label:before,
[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: @size-of-checkbox;
  height: @size-of-checkbox;

  background: #fff;

  border: 1px solid #2289a0;
  border-radius: 0.3em;
}
/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after,
[type='radio']:not(:checked) + label:after,
[type='radio']:checked + label:after {
  content: '';
  position: absolute;
  top: -8px;
  left: 6px;

  width: 14px;
  height: 24px;

  box-shadow: 4px 4px 0 0 #ed1c24;

  transform: rotate(45deg);

  transition: all 0.2s;
}
/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after,
[type='radio']:not(:checked) + label:after {
  opacity: 0;
}

[type='checkbox']:checked + label:after,
[type='radio']:checked + label:after {
  opacity: 1;
}
/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label:before,
[type='checkbox']:disabled:checked + label:before,
[type='radio']:disabled:not(:checked) + label:before,
[type='radio']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type='checkbox']:disabled:checked + label:after,
[type='radio']:disabled:checked + label:after {
  color: #999;
}
[type='checkbox']:disabled + label,
[type='radio']:disabled + label {
  color: #aaa;
}

.md-visible {
  display: none;
}

.pop-up_form {
  padding: 15px;

  text-align: center;
  font-size: 16px;

  background: #f6f6f6;

  position: relative;
  z-index: 59999999999;

  p {
    margin-bottom: 15px;

    line-height: 1.4;
  }

  form {
    text-align: left;
    font-size: 14px;
  }

  input,
  textarea {
    border-color: #d4e0f0;
  }

  .btn {
    border-color: #13a1ff;
  }
}

.form_el_invalid,
input[type='text'].form_el_invalid,
.wrapped_select,
select.wrapped_select {
  border: 1px solid @link-color !important;
}

.lightTip {
  .content {
    background-color: #fff;
    border: 6px solid @link-color;
    border-radius: 5px;
    color: #000;
    padding: 9px 18px 10px 18px;
  }

  .bottom {
    position: absolute;
    bottom: -8px;
    left: 50%;

    width: 29px;
    height: 14px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 6px;
      left: -2px;
      width: 29px;
      height: 29px;
      background-color: #fff;
      border: 6px solid @link-color;
      transform: rotate(45deg);
    }
  }
}

.fancybox-close {
  z-index: ~'calc(100 + 88)';
}

.fancybox-close.fancybox-close_form {
  background: url(/images/close-2.png) no-repeat !important;
  top: 2px;
  right: 2px;
  width: 21px;
  height: 21px;

  transition: 0.3s;
}

.fancybox-close.fancybox-close_form:hover {
  transform: scaleX(-1);
}

.fancybox-overlay {
  background-color: rgba(77, 76, 77, 0.7);
}

.fancybox-inner {
  text-align: center;
  background: #fff;
}

.hint {
  background-color: #2289a0;
  border-radius: 15px;
  height: 13px;

  font-size: 8px;
  color: #fff;

  padding: 2px 5px;
  margin-left: 3px;

  position: relative;
  top: -5px;

  &::after {
    content: '?';
  }
}

.hint-wrap {
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: solid 1px #ccc;

  color: #4a4a4a;
  font-size: 12px;
  text-align: left;

  display: block;
  width: 240px;

  padding: 10px;

  position: absolute;
  top: 17px;
  left: -205px;

  visibility: hidden;
  opacity: 0;

  z-index: 99999;
  transition: opacity 0.4s;

  &::before {
    content: '';

    background-color: #fff;
    border-color: #ccc;
    border-style: solid;
    border-width: 0 1px 1px 0;

    width: 5px;
    height: 5px;

    position: absolute;
    top: -3px;
    left: 207px;

    transform: rotate(-135deg);
    z-index: 100999;
  }

  & > span {
    font-weight: normal;
    line-height: 18px;

    display: block;
  }
}

.hint-wrap_left {
  left: -20px;

  &::before {
    left: 23px;
  }
}

.hint:hover .hint-wrap,
.hint:active .hint-wrap {
  opacity: 1;
  visibility: visible;
}

.media-imgs {
  .media-img-greate {
    margin-bottom: 15px;
  }

  .bx-viewport {
    display: block;
    width: ~'calc(100% - 45px)' !important;

    margin: 0 auto 10px;
  }
}

.media-imgs-more {
  display: flex;
}

.media-imgs-more__item {
  margin: 0 5px;

  border: 1px solid #eee;

  width: 82px;
  flex-shrink: 0;
}

.media-imgs-more__item.active {
  border: 1px solid #000;

  position: relative;
}

.media-imgs-more__item.active::before,
.media-imgs-more__item.active::after {
  content: '';
  position: absolute;

  transform: rotate(45deg);
}

@arrow-front-width: 8px;
@arrow-border-width: 1px;
@arrow-back-width: @arrow-front-width + @arrow-border-width;

.media-imgs-more__item.active::before {
  top: -(@arrow-front-width);
  left: calc(50% - @arrow-front-width);

  width: 2 * @arrow-front-width;
  height: 2 * @arrow-front-width;

  border: @arrow-front-width solid transparent;
  border-left-color: #fff;
  border-top-color: #fff;

  z-index: 9;
}

.media-imgs-more__item.active::after {
  top: -(@arrow-back-width);
  left: calc(50% - @arrow-back-width);

  width: 2 * @arrow-back-width;
  height: 2 * @arrow-back-width;

  border: @arrow-back-width solid transparent;
  border-left-color: #000;
  border-top-color: #000;
}

.bx-wrapper {
  position: relative;
  overflow: hidden;

  margin: 0 auto;
  padding: 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 8;
}

.bx-wrapper .bx-controls-direction {
  .bx-prev,
  .bx-next {
    background-image: url(/images/sprite-arrow.png);

    height: 40px;
    width: 22px;

    top: calc(50%-35px);

    margin-top: 0;
  }

  .bx-next {
    background-position: center right;

    right: 0;
  }

  .bx-prev {
    left: 0;
  }
}

.elem-list_item-price-block {
  padding: 0;
}

.price-good {
  font-size: 18px;
  font-weight: normal;

  margin-bottom: 0;
}

.price-good .elem-list_item-price {
  font-size: 34px;
}

.price-good .no-price {
  font-size: 0.8em;
}

.elem-list_item-price-block .price__btn .btn {
  width: auto;

  font-weight: bold;
  font-size: 1.2em;
}

.elem-list_item-price-block .price__btn {
  margin-bottom: 25px;
}

table {
  border-collapse: collapse;

  font-size: 14px;
  line-height: 1.1;
}

.table:not(.table_min) {
  table-layout: fixed;
}

.tr-img td {
  vertical-align: middle;
}

.td-price {
  color: @black-color;
  font-weight: bold;
}

td {
  border: 1px solid #ccc;
  padding: 7px 10px;
}

td:first-child p::before {
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  left: 0;
}

td:first-child .ico-people::before {
  width: 26px;
  top: -4px;
}

td:first-child .ico-crane::before {
  width: 29px;
  top: -2px;
  left: -2px;
}

td:first-child .ico-wc::before {
  width: 24px;
  top: -4px;
  left: 1px;
}

td:first-child .ico-shower::before {
  width: 26px;
  top: -3px;
  left: -1px;
}

td:first-child .ico-washer::before {
  width: 24px;
  top: -6px;
}

.table thead td,
.table thead th {
  background-color: #2289a0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 0 2px;
}

.table td:before {
  content: attr(data-name);
  order: 2;
  color: #2289a0;
  font-weight: bold;
  width: 50%;
  text-align: left;
}

.table td[data-type] {
  flex-wrap: wrap;
}

.table td {
  span {
    order: 3;
  }
}

.table td[data-type]:after {
  content: attr(data-type);
  order: 1;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #333333;
  font-weight: bold;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #e6e7e8;
}

.table thead td.tabl-hidden,
.table thead th.tabl-hidden,
.table-less-width td.tabl-hidden {
  display: none;
}

.table td {
  border: none;
}

.table tr:nth-child(odd) .ul li::after {
  border-right-color: #e8e8e8;
}

.table td,
.table th {
  border: 1px solid #fff;
  border-top: none;
  border-bottom: none;
}

.table tbody td {
  border-top: none;
  border-bottom: none;
}

.table_min {
  border: 1px solid #e6e7e8;
  border-top-color: #2289a0;
}

.table_min tbody td {
  border-color: #e6e7e8;
  border-width: 0 1px;
}

.table:not(.table_min) tbody td {
  display: flex;
  flex-direction: column;
}

.table td:first-child {
  font-size: 16px;
  color: white;
  text-align: left;
  padding-left: 25px;
}

.table:not(.table_min) tbody td:not(:first-child) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table td:first-child a,
.table td:first-child .f-black {
  color: #fff;
}

.table:not(.table_min) tbody td:nth-child(odd),
.table_min tbody tr:nth-child(even) td {
  background-color: #f2f3f3;
}

.table:not(.table_min) tbody td:first-child {
  background-color: #2289a0;
}

.table-montazh__disc {
  margin-bottom: 2px;
}

.table-montazh__disc .discount-proc {
  font-size: 16px;
}

.table-montazh__disc .discount-text {
  left: 0;
  top: -2px;
}

.tabl-mob-key {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 48%;
}

.button-table {
  padding: 6px 10px;
}

.b-pagination {
  .b-pagination_numbers {
    display: inline-block;
    vertical-align: top;

    li {
      height: 36px;
      width: auto !important;
      min-width: 36px;
      text-align: center !important;
      background-color: @white-color;
      line-height: 2;
      box-shadow: none !important;
      margin-right: 0;
      display: inline-block;
      vertical-align: top;

      a {
        color: @dark-gray-color;
        font-size: 18px;
        text-decoration: none;
        display: block;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid @gray-color;
        }
      }

      &.active {
        a {
          background-color: @bg-btn-color;
          border: 1px solid @bg-btn-color;
          color: @font-color;
        }
      }
    }
  }

  .b-pagination_prev,
  .b-pagination_next {
    display: inline-block;
    vertical-align: top;
    height: 36px;
    width: 36px;
    background-color: @white-color;

    a {
      color: transparent;
      display: block;
      height: 36px;
      width: 36px;
      border: 1px solid @white-color;

      &:hover {
        cursor: pointer;
        border: 1px solid @gray-color;
      }
    }
  }

  .b-pagination_prev {
    a {
      background: url(/images/arrow-prev.png) center center no-repeat;
    }
  }

  .b-pagination_next {
    a {
      background: url(/images/arrow-next.png) center center no-repeat;
    }
  }

  .b-pagination_all {
    display: none;
  }
}

.table {
  width: 100%;

  .thead {
    tr {
      td {
        background-color: @light-gray-color;
        font-size: 14px;
        border: 1px solid #dfe0e1;
      }
    }
  }

  tr {
    td,
    th,
    .not-first:first-child {
      text-align: center;
      padding: 10px 5px;
      vertical-align: middle;

      a {
        &.a-green {
          padding-left: 15px;
          border-bottom: none;

          &:before {
            content: '>';
            bottom: 0px;
          }
        }
      }
    }
  }

  .tbody {
    tr {
      td {
        &:first-child {
          background-color: @white-color;
        }
      }
    }
  }
}

.table.scroll {
  .tbody {
    max-height: 300px;
    overflow-y: scroll;

    & > div {
      display: table;
    }
  }

  .thead {
    width: 100%;
    background-color: @light-gray-color;
    border-top: 1px solid #dfe0e1;
    display: table;

    .tr {
      .td {
        text-align: center;
        display: table-cell;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
  .tr {
    text-align: left;
    display: flex;
    flex-direction: row;

    .td {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding: 2px 7px;
      font-size: 10px;
      line-height: 1.3;
      border-bottom: 1px solid #dfe0e1;

      &:first-child {
        width: 59%;
        border-right: 1px solid #dfe0e1;
        border-left: 1px solid #dfe0e1;
      }
      &:nth-child(2) {
        width: 15%;
        text-align: center;
      }
      &:last-child {
        flex-grow: 2;
        border-left: 1px solid #dfe0e1;
        border-right: 1px solid #dfe0e1;
      }
    }
  }
}

#description_top {
  a {
    color: @link-color;
    margin: 5px 0 30px;
    display: unset;
  }
}

.fake_table {
  margin-top: 53px !important;

  tr {
    background-color: @light-gray-color;
    font-size: 14px;
  }
}

.form {
  width: 100%;
}

footer {
  margin-top: 30px;
}

.w-120 {
  width: 120%;
}
.b-r20 {
  border-radius: 20px;
}

.no-link {
  pointer-events: none;
}

.header {
  display: flex;
  flex-direction: column;

  padding-top: 40px;
}

.header__item-cont {
  background-color: #fff;

  color: #333;
}

.top-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  padding: 5px 10px;

  position: relative;
  z-index: 99;
}

.top-contacts__contacts-link a {
  color: #333;
  text-decoration: underline;
}

.top-contacts__contacts-link a:hover {
  text-decoration: none;
}

.sidr .top-contacts {
  flex-direction: column;
}

.a-cont {
  color: #333;
  text-decoration: none;
}

.a-cont:hover {
  text-decoration: underline;
}

.top-contacts .a-tel {
  font-weight: 500;
  line-height: 1;

  margin-right: 20px;
}

.header__item-search {
  background-color: #2289a0;
}

#ya-site-form0 .ya-site-form__search-input,
#ya-site-form0 .ya-site-form__search-wrap {
  padding: 0;
  border: none;
}

.top-search form,
.ya-site-form__search-input-layout tr {
  display: flex;
}

#ya-site-form0 .ya-site-form__search td {
  display: flex;
  flex-direction: column;
}

.top-search .input-search,
#ya-site-form0 .ya-site-form__form .ya-site-form__input-text {
  margin-bottom: 0;
  padding: 5px 10px;

  border: 1px solid #c4c4c4;
  border-right: none;
}

.top-search .search_button,
#ya-site-form0 .ya-site-form__submit {
  padding: 5px 30px;
  margin: 0;

  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-left: none;
  border-radius: 0;

  background-color: #424242;
  background-image: url(/images/ico-search.png);
  background-repeat: no-repeat;
  background-position: center;

  box-shadow: none;

  outline: none;
  cursor: pointer;

  color: #fff;
  font-size: 0 !important;
}

#ya-site-form0 .ya-site-form__submit {
  flex-grow: 1;
}

.header__item-menu {
  background-color: #2a3233;

  display: none;
}

.main__item-menu {
  background-color: #fff;

  display: block;
}
.header__item-menu {
  z-index: 390;
}

.header-menu {
  .ul-lvl-1 {
    list-style: none;
    .li-lvl-1 {
      position: relative;
      .a-lvl-1 {
        font-size: 11px;
        font-family: 'Roboto';
        color: #ffffff;
        text-transform: uppercase;

        padding: 3px 10px;
        display: inline-block;

        cursor: pointer;
        &:hover,
        &.active {
          text-decoration: underline;
        }
        @media(max-width: 992px) {
          color: #666;
        }
      }
    }
    .collapsible-menu {
      list-style-type: none;
      display: none;
      padding: 20px 14px 12px;
      background-color: #2a3233;
      color: #ffffff;
      width: 164px;
      .li-lvl-2 {
        list-style-type: none;
        margin-bottom: 8px;
        cursor: pointer;
        .a-lvl-2 {
          font-family: 'Roboto';
          font-weight: normal !important;
          font-size: 11px;
          line-height: 14px;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }

    .checked_menu {
      display: block;

      position: absolute;
      left: 0;
      top: 20px;
      z-index: 990 !important;
    }
  }

  .arrow_menu_collapsible {
    position: relative;
    &:after {
      content: url('/images/arrow_menu_collapsible.svg');
      // width: 9px;
      // height: 7px;
      position: absolute;
      right: -8px;
      top: 3px;
    }
  }
}

.main-menu {
  padding: 10px 20px;

  ul {
    justify-content: space-between !important;

    li:before {
      top: 8px;
    }

    .a-lvl-1 {
      margin-right: 35px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      color: #2289a0 !important;
      text-transform: none !important;
      text-decoration: underline !important;
    }
  }
}

.top-search {
  padding: 5px 7px 15px;
}

.title-bg {
  background-position: center center;
  background-size: cover;

  padding: 30px 0;
}

.title-bg_ex {
  background-image: url(/images/title-bg_ex.jpg);
}

.decor-title {
  display: inline-block;

  background-color: #fff;

  padding: 25px 15px 20px;

  color: @font-color;
}

.decor-title p {
  margin-bottom: 0;

  font-size: 22px;
}

.decor-title .decor-title__tit {
  font-size: 40px;
}

.items-list {
  display: grid;
  grid-template-columns: 1fr;

  width: 100%;

  @media (min-width: 610px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  @media (min-width: 915px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.search_result .items-list {
  @media (min-width: 610px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  @media (min-width: 915px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.nav {
  margin-bottom: 0;
}

.nav .ul-lvl-1,
.nav .ul-lvl-2,
.nav .ul-lvl-3 {
  list-style: none;
}

.nav .ul-lvl-1 {
  padding-bottom: 20px;
  margin-bottom: 0;
}

.nav .li-lvl-2,
.nav .li-lvl-3:not(:last-child) {
  border-bottom: 1px solid #f6f6f6;
}

.nav .a-lvl-1 {
  text-decoration: none;
  font-size: 16px;
  font-family: 'Roboto Condensed', 'Roboto', Arial, Helvetica, sans-serif;
  color: @font-color;

  margin-bottom: 0;
  padding: 10px;
  padding-bottom: 7px;
  display: inline-block;
}

.nav .a-lvl-2 {
  text-decoration: none;
  font-size: 14px;
  line-height: 1.4;
  color: #666;

  padding: 4px 20px;
  margin-bottom: 0;

  display: block;
}

.nav a.a-lvl-2.active,
.nav a.a-lvl-2:hover {
  text-decoration: underline;
}

.nav .a-lvl-3 {
  text-decoration: none;
  font-size: 14px;
  color: #666;

  padding: 5px 35px;
}

.nav a.a-lvl-3:hover,
.nav a.a-lvl-3.active {
  font-weight: bold;
}

.top-block .ul-lvl-2 {
  margin-bottom: 3px;
}

.sidr .sidr-inner {
  padding: 5px 0;

  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.sidr .sidr-inner:not(:last-child) {
  border-bottom: 1px solid #f6f6f6;
}

.bx-pager {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 15px;
}

.bx-pager-link {
  font-size: 18px;
  color: @font-color;
  text-decoration: none;

  padding: 2px 9px;
}

.bx-pager-link:hover {
  color: #fff;

  background-color: #2289a0;
}

.bx-pager-link.active {
  font-weight: bold;
}

.bx-pager-item {
  margin: 5px;
}

.slider-with-pager .bx-pager {
  margin-top: -42px;
  margin-bottom: 10px;

  position: relative;
  z-index: 9;
}

.slider-with-pager .bx-pager-link {
  font-size: 0;

  display: block;
  width: 15px;
  height: 15px;

  border: 3px solid #fff;
  border-radius: 50%;

  padding: 0;
}

.slider-with-pager .bx-pager-link.active,
.slider-with-pager .bx-pager-link:hover {
  border-color: #ed1c24;
  background-color: transparent;
}

.blue-title {
  background-color: #0067c3;

  color: #fff;
  text-align: center;
  font-size: 18px;

  padding: 3px 0 2px;
}

.good-media__item {
  background-color: #fff;

  margin-bottom: 15px;
  padding: 20px 10px;
}

.tech-desc_tit {
  font-weight: bold;
}

.good-chars__item {
  border-bottom: 1px dotted #333;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.good-chars__elem {
  background-color: #fff;

  position: relative;
  bottom: -4px;

  color: #333;
}

.good-chars__label {
  font-size: 14px;
}

.good-chars__field {
  font-size: 12px;
}

.link-char {
  color: #69c;
  font-size: 14px;
}

.detail-charact__title {
  background-color: #818181;

  color: #fff;
  font-size: 18px;

  margin-bottom: 0;
}

.detail-charact__name {
  background-color: #f6f6f6;

  flex-grow: 1;
}

.detail-charact__field,
.detail-charact__name {
  color: #1c1c1c;

  display: flex;
  align-items: flex-end;

  border-bottom: 1px solid #818181;
}

.detail-charact__field,
.detail-charact__name,
.detail-charact__title {
  padding: 5px 10px;
}

.detail-charact__char {
  display: flex;
}

.detail-charact__field {
  width: 30%;

  flex-shrink: 0;

  font-weight: bold;
}

.big-table .detail-charact__field {
  width: 60%;
}

.elem-list_item-img {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5px;
}

.image-link {
  overflow: hidden;
}

.image-link .image-img {
  transition: 0.3s;
}

.image-link:hover .image-img {
  transform: scale(1.1, 1.1);
}

.link_plus {
  position: relative;
}

.main-image-link::before {
  content: '+';
  color: #000;
  text-decoration: none;
  text-align: center;
  font-size: 18px;

  position: absolute;
  top: 6px;
  right: 6px;

  border: 3px solid #f2f2f2;
  border-radius: 50%;

  padding: 0.15em 0.55em 0.05em;
}

.elem-list_item-img .checkbox {
  margin-top: 7px;
}

.goods-table .elem-list_item-img {
  background-color: transparent;
}

.breadcrumbs {
  list-style: none;

  margin-top: 10px;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

.breadcrumbs li {
  display: inline-block;
}

.breadcrumbs li::after {
  content: '>';
  margin-left: 5px;
}

.breadcrumbs li:last-child::after {
  content: '';
}

.breadcrumbs a {
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs a,
.breadcrumbs li {
  font-size: 12px;
  color: #333;
}

#preloader {
  display: none;
}

.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}

.show-all {
  background-color: transparent;
  background-image: none;

  border: none;

  padding: 0;

  cursor: pointer;

  text-decoration: underline;
  font-size: 1em;
  color: #69c;
}

.show-all::after {
  content: '';

  display: inline-block;

  margin-left: 5px;

  height: 10px;
  width: 10px;

  border-right: 1px solid #69c;
  border-bottom: 1px solid #69c;

  transform: rotate(45deg);
  transform-origin: 100% 100% 0;
  transition: 0.5s;
}

.show-all:hover {
  text-decoration: none;
  outline: none;
}

.show-all:active {
  outline: none;
}

.content-404 {
  max-width: 100%;
  width: 380px;

  margin: 0 auto;
}

.good-media .btn,
.good-media .btn-link {
  width: 100%;
}

.comparison-table__name {
  color: #1c1c1c;
  font-weight: bold;
}

.comparison-table__title,
.left-table .comparison-table__title {
  background-color: #818181;

  color: #fff;
  font-size: 18px;

  border: 1px solid #818181;
}

.contect-with-img {
  display: flex;
  flex-direction: column;

  background-color: #fff;
  max-width: 100%;

  margin-bottom: 25px;
}

.contect-with-img__img,
.contect-with-img__noimg {
  text-align: center;
}

.contect-with-img__desc,
.contect-with-img__noimg {
  padding: 15px 10px;
}

.plus {
  list-style: none;
}

.plus__item:not(:last-child) {
  margin-bottom: 15px;
}

.plus__item {
  display: flex;
}

.plus__item::before {
  content: '';

  flex-shrink: 0;

  width: 40px;
  height: 45px;
  display: block;
  margin-right: 10px;

  background-image: url(/images/sprite.png);
  background-position-x: -170px;
}

.plus__time::before {
  background-position-y: -105px;
}

.plus__glass::before {
  background-position-y: -170px;
}

.plus__nose::before {
  background-position-y: -235px;
}

.plus__water::before {
  background-position-y: -310px;
}

.plus_horizontal .plus__item {
  align-items: center;
}

.link-next {
  background-color: #ed1c24;

  display: block;
  height: 40px;
  width: 40px;

  border-radius: 50%;

  position: relative;
  transition: 0.5s;
}

.link-next::before {
  content: '';
  position: absolute;
  top: 19px;
  left: 10px;

  height: 2px;
  width: 20px;
  background-color: #fff;

  margin-right: 10px;
}

.link-next::after {
  content: '';
  position: absolute;
  top: 14px;
  left: 16px;

  display: block;
  height: 12px;
  width: 12px;

  box-sizing: border-box;
  border: 2px solid #fff;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
}

.link-next:hover {
  background-color: #900;

  transform: scale(1.2);
}

.btn-down {
  background-color: #009c41;

  display: block;
  height: 26px;
  width: 26px;

  border-radius: 50%;
  border: none;

  position: relative;
  transition: 0.5s;
}

.btn-down::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 12px;

  height: 15px;
  width: 2px;
  background-color: #fff;
}

.btn-down::after {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;

  display: block;
  height: 12px;
  width: 12px;

  box-sizing: border-box;
  border: 2px solid #fff;
  border-left-color: transparent;
  border-top-color: transparent;

  transform: rotate(45deg);
}

.btn-down:hover,
.btn-down:focus {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3) 100%
  );
  outline: none;

  transform: scale(1.2);
}

.btn-up {
  transform: rotate(180deg);
  transition: 0.3s;
}

.btn-up:hover,
.btn-up:focus {
  transform: scale(1.2) rotate(180deg);
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidr ul {
  margin: 0;
}

.ol {
  margin-left: 2em;
  counter-reset: list1;

  li {
    list-style-type: none !important;
    list-style-type: decimal;

    &::before {
      counter-increment: list1;
      content: counter(list1) '. ';
      visibility: hidden;
      font-size: 0;
    }

    ol {
      counter-reset: list2;

      li {
        &:before {
          counter-increment: list2;
          content: counter(list1) '.' counter(list2) '. ';
          visibility: visible;
          font-size: 100%;
        }

        ol {
          counter-reset: list3;

          li {
            &:before {
              counter-increment: list3;
              content: counter(list1) '.' counter(list2) '. ' counter(list3)
                '. ';
              visibility: visible;
              font-size: 100%;
            }
          }
        }
      }
    }
  }
}

.ol_first > li {
  list-style-type: decimal !important;
}

.top-contacts__title,
.sidr .top-contacts__title {
  margin: 0 0 10px;

  font-size: 16px;
  font-family: Roboto Condensed, Roboto, Arial, Helvetica, sans-serif;
  color: #000;
  text-transform: uppercase;
  text-align: center;

  padding: 3px 10px;
  display: block;
  width: 100%;
}

.top-contacts__title {
  display: none;
}

.sidr .top-contacts__title {
  display: block;
}

.sidr .top-contacts {
  padding: 0 10px;

  align-items: baseline;
}

.sidr .top-contacts__contacts-link {
  display: none;
}

.sidr .call-me {
  margin: 0 auto;

  display: block;
}

.contacts-title,
.sidr .contacts-title {
  font-size: 11px;
  color: #333;
  line-height: 1;

  margin-bottom: 10px;
}
.sidr-open .sidr-overlay {
  z-index: 99;
}
.contacts-title,
.top-contacts__item-email,
.top-contacts__item-callme {
  display: none;
}

.sidr .contacts-title,
.sidr .top-contacts__item-email,
.sidr .top-contacts__item-callme {
  display: block;
}

.contacts-title_main,
.sidr .contacts-title_main {
  font-weight: bold;
  color: #2289a0;
}

.sidr .top-contacts__item {
  margin-bottom: 20px;

  width: 100%;
}

.top-contacts .a-cont {
  font-size: 14px;
  font-weight: normal;
}

.icons-desc {
  display: flex;
  flex-wrap: wrap;
}

.icons-desc__item {
  width: 50%;

  font-size: 12px;

  display: flex;
  align-items: center;
}

.icons-desc__item::before {
  content: '';
  display: block;
  flex-shrink: 0;

  margin-right: 10px;
  height: 47px;

  background-image: url(/images/sprite.png);
}

.icons-desc__pay::before {
  width: 28px;

  background-position: -288px -98px;
}

.icons-desc__guarantee::before {
  width: 39px;

  background-position: -359px -98px;
}

.certificate__item {
  // border: 1px solid #ccc;

  display: block;
}

.certificate__name {
  border-bottom-color: #ed1c24;

  text-align: center;

  padding: 15px 10px;
  margin-bottom: 0;
}

.form-search {
  position: relative;

  width: 100%;
}

.search {
  background-color: #fff;
  border: 1px solid #68aebe;
  border-radius: @border-radius-search;

  width: 100%;
  padding: 5px;
  padding-right: 50px;
}

.form-search_big .search {
  border: none;
  border-radius: 0;

  padding-right: 5px;
}

.form-search_big .search:nth-child(1) {
  padding-bottom: 0;
}

.form-search_big .search:nth-child(2) {
  padding-top: 0;
}

.search__hidden {
  position: absolute;
  top: calc(100% - 9px);

  border-top: none;
  border-radius: 0 0 @border-radius-search @border-radius-search;

  padding-top: 0;

  z-index: 19;
}

.search__block {
  display: flex;
  flex-wrap: wrap;
}

.search__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;

  width: 100%;
}

.search__item {
  width: 49%;

  display: flex;
  align-items: center;

  font-size: 0.9em;

  padding-bottom: 10px;
}

.search__item_long {
  align-items: initial;
  flex-direction: column;
}

.search__item_long .search__item {
  width: 100%;
}

.search__item:not(:nth-child(2n)) {
  padding-right: 5px;
  margin-right: 2%;

  border-right: 1px solid #ccc;
}

.search__submit {
  width: 100%;
  justify-content: flex-end;

  padding-bottom: 0;
}

.form-search_big .search__submit {
  justify-content: center;

  margin-top: 15px;
}

.search_button-down {
  position: absolute;
  right: 5px;
  top: 6px;
}

.search__item label {
  margin-right: 5px;

  flex-grow: 1;
}

.search__item .input-search {
  border-color: #68aebe;
  box-shadow: inset 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

  padding: 3px;
  max-width: 40px;
}

.good-media__avail-disc_new {
  height: 45px;
  width: 73px;
  background: #2289a0;
  position: relative;
  top: -20px;
  left: 22px;
  margin-bottom: 0px;
  &_text {
    padding: 5px 0px 0px;
    text-align: center;
  }

  @media (min-width: 800px) {
    top: -10px;
    margin-bottom: 10px;
  }
}

.b-popup_prices .b-popup-content {
  width: 90%;
  max-width: 940px;
  box-sizing: border-box;
  margin: 20px auto;
}
.f-21 {
  font-size: 21px;
}

.cardbanner-block {
  background-image: url('/images/banner_card_back.jpg');
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;

  &_delivery {
    background-image: url('/images/banner_card_back-delivery.jpg');
  }

  &_text {
    background: hsla(0, 0%, 100%, 0.8);
    padding: 10px;
    p {
      font-size: 18px;
      color: #2289a0;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
    }
    span {
      font-size: 24px;
      white-space: nowrap;
    }
  }
  @media (min-width: 715px) {
    padding: 30px;
    &_text {
      padding: 20px;
      p {
        font-size: 33px;
      }
      span {
        font-size: 54px;
      }
    }
  }
}

.smeta-block {
  margin: 15px 0px;
  span {
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
    &:hover {
      text-decoration: none;
    }
  }
}

.good-media__avail-disc_new:before {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 45px;
  border-top: 15px solid #2289a0;
  border-left: 36px solid transparent;
  border-right: 37px solid transparent;
  border-bottom-color: transparent;
}

.good-media__avail-disc_new p {
  margin-bottom: 0;
  color: #fff;
  text-align: center;
}

.elem-item__disc {
  position: relative;
}

.discount-text {
  position: relative;
  bottom: 12%;
  left: 1%;
}

.discount-proc {
  color: #009c41;
  font-weight: bold;
  font-size: 30px;
}

@media (min-width: 425px) {
  .bx-wrapper img {
    margin: 0 auto;
  }
  .elem-list_item-img img {
    width: auto;
  }

  .gallery__item {
    width: 49%;
  }
}

@media (min-width: 500px) {
  .elem-list_item-price-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
  }

  .elem-list_item-price-block .price__btn {
    margin-bottom: 0;
  }
}

@media (min-width: 600px) {
  .after-float {
    display: flex;
    flex-direction: column;
  }

  .sm-block {
    display: block;
  }
  td.sm-block {
    display: table-cell;
  }

  .j-bxslider-video {
    li {
      iframe {
        height: 300px;
      }
    }
  }

  .h-contacts {
    padding: 0;
  }
  .xs-hidden {
    display: block;
  }

  .a-cont_without-icon {
    padding-left: 0;
  }

  .a-cont_without-icon::before {
    display: none;
  }

  .sm-hidden {
    display: none;
  }

  .fake_table {
    margin-top: 119px !important;
  }

  .top-search input,
  .ya-site-form__search-input-layout-l {
    max-width: 325px;
    width: 100%;
  }

  .top-search {
    order: 5;
    width: 100%;
  }

  .top-search form,
  .ya-site-form__search-input-layout tr {
    justify-content: center;
  }

  .footer {
    padding: 30px 0;
  }

  .footer-contacts {
    display: flex;
  }

  .footer-contacts__item:first-child {
    flex-grow: 1;
  }

  .footer-contacts__item:not(:last-child) {
    border: none;

    padding-bottom: 0;
    margin-bottom: 0;
  }

  .footer-contacts__item {
    padding: 10px 50px 10px 0;
  }

  .footer-contacts__item:not(:last-child) {
    margin-right: 50px;

    border-right: 1px solid #999;
  }

  .copyright {
    text-align: center;
  }

  .search__block.search__hidden {
    margin-bottom: 0;
  }

  .search__title {
    width: 18%;

    margin-bottom: 0;
    padding-right: 10px;
    margin-right: 2%;
    border-right: 1px solid #ccc;

    display: flex;
    align-items: center;
    a {
      text-decoration: underline !important;
    }
  }
  .search__title a:hover {
    text-decoration: none !important;
  }

  .form-search_big .search__title {
    width: 100%;

    padding-right: 0;
    margin-right: 0;
    margin-bottom: 10px;
    border-right: 0;

    justify-content: center;
  }

  .search__title + .search__item {
    width: 29%;
  }

  .search__hidden .search__item,
  .form-search_big .search__item {
    width: 49%;

    margin-bottom: 20px;
  }

  .search__hidden {
    padding-top: 17px;
  }

  .search__hidden .search__submit,
  .form-search_big .search__submit {
    width: 100%;

    margin-bottom: 0;
  }

  .search__item:not(:nth-child(2n)) {
    padding-right: 0;
    border-right: 0;
  }

  .search__item {
    padding-bottom: 0;
  }

  .search__item_long {
    flex-direction: row;
    align-items: center;
  }

  .search__item_long .search__item {
    flex-grow: 1;
    justify-content: flex-end;

    margin-bottom: 0;
  }

  .search__item_long .search__item label {
    flex-grow: 0;

    margin-left: 20px;
  }
}

@media (min-width: 630px) {
  .img-l {
    float: left;
    margin: 0 20px 15px 0;
  }

  .img-r {
    float: right;
    margin: 0 0 15px 20px;
  }
}

@media (min-width: 1010px) {
  table {
    border: 1px solid #e6e7e8;
    border-top: 1px solid #2289a0;
  }

  .table tbody tr td:first-child a {
    color: #2289a0;
  }

  .table tbody td,
  .table:not(.table_min) tbody td {
    display: table-cell;
    border: 1px solid #e6e7e8;
    border-top: none;
    border-bottom: none;
  }

  .table tbody tr td:first-child a {
    color: #2289a0;
  }

  .table thead td:first-child {
    border-left: 1px solid #2289a0;
  }

  .table thead td:last-child {
    border-right: 1px solid #2289a0;
  }

  .table-less-width .table thead td {
    font-size: 18px;
  }

  .table-less-width .table thead td:first-child,
  .table-less-width .table tbody tr td:first-child {
    text-align: left;
    padding-left: 30px;
  }

  .table-less-width {
    width: 65%;
    margin: 0 auto;
  }

  .table td:before {
    display: none;
  }
  .table td[data-type]:after {
    display: none;
  }
  .table td:after {
    display: none;
  }

  .table tbody td:nth-child(odd),
  .table:not(.table_min) tbody td:nth-child(odd) {
    background-color: white;
  }

  .table thead td.tabl-hidden,
  .table tbody td:not(:first-child),
  .table thead th.tabl-hidden,
  .table-less-width td.tabl-hidden {
    display: table-cell;
  }

  .table tbody td:first-child,
  .table:not(.table_min) tbody td:first-child {
    background-color: white;
    color: #2289a0;
    text-align: center;
    padding-left: 5px;
  }

  .table tbody tr:nth-child(even) td:first-child {
    background-color: #f2f3f3;
  }

  .table tbody td:not(:first-child),
  .table:not(.table_min) tbody td:not(:first-child) {
    display: table-cell;
  }

  .table tbody tr:nth-child(even) td:nth-child(odd) {
    background-color: #f2f3f3;
  }

  .table tr:nth-child(even) {
    background-color: #f2f3f3;
  }

  .price-table {
    margin-bottom: 20px;
  }

  .table .border-table {
    border-bottom: 1px solid white;
  }

  .tabl-name {
    width: 14%;
  }

  .tabl-key {
    width: 17%;
    text-align: left;
  }

  .tabl-mob-key {
    display: block;
    width: 100%;
  }

  .button-table {
    padding: 4px 17px;
  }

  .table tr td.tabl-montazh-but {
    padding: 6px 5px;
  }

  .table.table--small tbody tr:nth-child(2n) td:nth-child(odd),
  .table.table--small tr:nth-child(2n) {
    background-color: #ffffff;
  }

  .table.table--small tbody tr td:not(:last-of-type) {
    border-bottom: 1px solid #e6e7e8;
  }

  .table td:first-child .f-black {
    color: #000000;
  }
}

@media (min-width: 768px) {
  .h1,
  .h2,
  .h3,
  h1,
  h2,
  h3 {
    margin-bottom: 20px;
  }

  body {
    font-size: 15.5px;
  }

  .table.scroll {
    .tr {
      .td {
        font-size: 13px;
      }
    }
  }

  .j-bxslider-video li iframe {
    height: 400px;
  }

  .h-pt10-lg {
    padding-top: 10px;
  }

  .h-pt30-lg {
    padding-top: 30px;
  }

  .contact-form {
    display: flex;
    align-items: center;
  }

  .elem-list_item-name {
    font-size: 1.1em;
  }

  .elem-list_listing .items-list {
    flex-direction: column;
  }

  .elem-list_listing .elem-list_item {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;

    flex-direction: row;
  }

  .elem-list_listing .elem-list_item-img {
    width: 200px;

    flex-shrink: 0;
    flex-grow: 0;
  }

  .elem-list_listing .elem-list_item-descr {
    flex-grow: 1;

    padding: 20px 10px;
  }

  .elem-list_listing .elem-list_item-name {
    text-align: left;
  }

  .elem-list_listing .elem-list_item-desc {
    padding: 0;

    border: none;
  }

  .elem-list_listing .price {
    margin: 20px 0;
    padding: 0 25px;

    border-left: 1px solid #d0d0d0;
  }

  .contect-with-img {
    flex-direction: row;

    margin-bottom: 35px;
  }

  .contect-with-img__img,
  .contect-with-img__noimg {
    flex-shrink: 0;
    max-width: 40%;
  }

  .contect-with-img__noimg-all-width .certificates {
    display: flex;
    flex-wrap: wrap;
  }

  .contect-with-img__noimg-all-width .certificate {
    margin: 5px;
  }

  .contect-with-img__noimg-all-width {
    min-width: 100%;
  }

  .contect-with-img__desc {
    flex-grow: 1;

    width: 60%;
  }

  .flexbox-wrapper {
    display: flex;
    align-items: center;
  }

  .flexbox-jc-sb {
    justify-content: space-between;
  }

  .flexbox-fs-0 {
    flex-shrink: 0;
  }

  .goods-desc_block {
    font-size: 0.87em;
  }

  .pop-up_form form {
    width: 500px;
  }
}

@media (min-width: 800px) {
  .table.scroll .thead {
    width: ~'-webkit-calc(100% - 17px)';
    width: ~'-moz-calc(100% - 17px)';
    width: ~'calc(100% - 17px)';
  }

  .short-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .short-input {
    width: 48.5%;
  }

  .detail-charact__field {
    width: 40%;
  }

  .detail-charact__field,
  .detail-charact__name,
  .detail-charact__title {
    padding: 5px 50px;
  }

  .detail-charact__title {
    font-size: 22px;
  }

  .good-media {
    display: flex;

    padding: 0;
  }

  .media-imgs {
    width: 45%;
    padding: 20px 15px;
    box-sizing: border-box;

    flex-shrink: 0;
  }

  .good-media__item:nth-child(odd) {
    margin-right: 25px;
  }

  .goods-desc_block {
    flex-grow: 1;

    padding: 10px;
  }

  .btn-link {
    font-size: 18px;
  }

  .elem-list .btn-link {
    font-size: 14px;
  }

  .tech-description {
    padding-bottom: 20px;
  }

  .doc-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
  }

  .doc-list__item {
    width: 48%;
  }

  .plus_horizontal {
    display: flex;
    flex-wrap: wrap;
  }

  .plus_horizontal .plus__item {
    width: 25%;

    text-align: center;

    padding: 2px 10px;
    margin-bottom: 0;
  }

  .plus_horizontal .plus__item:nth-child(4n) {
    padding-right: 0;
  }

  .plus_horizontal .plus__item:nth-child(4n + 1) {
    padding-left: 0;
  }

  .plus_horizontal .plus__item:not(:nth-child(4n)) {
    border-right: 1px solid #ccc;
  }

  .search {
    padding-left: 30px;
    padding-right: 65px;
  }

  .search__item {
    width: 29%;
  }

  .search_button-down {
    right: 15px;
  }
}

@media (min-width: 992px) {
  .header {
    padding-top: 0px;
  }

  .header__item-menu {
    display: block;
  }

  .header-menu .ul-lvl-1 {
    list-style: none;

    display: flex;
    flex-wrap: wrap;
  }

  .header-menu .a-lvl-1 {
    color: #fff;
    text-decoration: none;
    font-size: 11px;
    font-weight: 900;
    font-family: Roboto, Arial, Helvetica, sans-serif;

    display: block;

    margin-right: 50px;
    padding-right: 0;
    padding-left: 0;

    &:hover,
    &.active {
      text-decoration: underline;
    }
  }

  .header-menu .li-lvl-1:last-child .a-lvl-1 {
    margin-right: 0;
  }

  .all-content__body {
    display: flex;
    flex-direction: column;
  }

  .container {
    max-width: 1200px - @menu-width;
  }

  .content-block {
    padding: 27px 0;
  }

  .h1,
  h1 {
    font-size: 36px;
  }
  .h2,
  h2 {
    font-size: 30px;
  }
  .h3,
  h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  .md-block {
    display: block;
  }

  .content {
    margin-top: 0;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .all-content__content {
    flex-grow: 1;

    margin: 0 auto;

    max-width: @content-width;
  }

  .all-content__top,
  .slider-with-pager_top .bx-controls {
    padding-left: @menu-width + 25px;
    margin: 0 auto;

    max-width: @content-width;
  }

  .top-block {
    position: absolute;
    top: 0;

    width: @menu-width;

    z-index: 999;

    .top-menu {
      width: 100%;
      padding-bottom: 17px;
      padding-top: 13px;
      position: relative;
      border-bottom: 1px solid #dfe0e1;
      background-color: #fff;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .top-block__item {
    background: #fff;

    width: 100%;
    margin-bottom: 35px;
  }

  .top-block__item {
    width: @menu-width;
    max-width: 100%;

    background-color: #fff;
  }

  #mobile-header {
    display: none;
  }

  .logo-block {
    padding: 10px 15px 15px;
    z-index: 9999;
    display: block;
    box-shadow: 0px 3px 15.36px 0.64px rgba(51, 51, 51, 0.34);

    text-align: center;

    .logo {
      width: auto;
      z-index: 9999;
    }
    a {
      z-index: 9999;
    }
  }

  .logo-subtitle {
    font-size: 1.1em;

    margin-bottom: 10px;
  }

  .a-tel,
  .top-contacts .a-tel {
    margin-right: 0;
  }

  #navigation {
    display: block;
  }

  .md-hidden {
    display: none !important;
  }
  .md-visible {
    display: block;
  }

  .pop-up_form {
    p {
      &.h3 {
        font-size: 23px;
      }
    }
  }

  .block_elem-list {
    padding-bottom: 20px;

    .elem-list_item {
      padding: 20px 10px;
      margin-bottom: 28px;
    }

    .elem-list_item-img {
      margin-bottom: 23px;
    }

    .elem-list_item-name {
      margin-bottom: 10px;
    }

    .elem-list_item-price-block {
      .checkbox {
        margin-bottom: 17px;
      }
    }
  }

  .elem-list_listing .elem-list_item {
    padding: 0;
  }

  .elem-list_listing .elem-list_item-img {
    margin-bottom: 0;
  }

  ul.j-bxslider-video {
    li {
      text-align: center;

      iframe {
        max-width: 855px;
        height: 475px;
        margin-top: 15px;
      }
    }
  }

  .block-video {
    padding-bottom: 25px;

    .bx-wrapper {
      .bx-viewport {
        background: transparent;
      }
    }
  }

  .tech-description {
    p {
      font-size: 16px;
      line-height: 1.65;

      &.tech-desc_tit {
        margin-bottom: 10px;
      }
    }
  }

  .fake_table {
    margin-top: 0 !important;
  }

  .top-contacts,
  .header-menu,
  .container {
    margin: 0 auto;

    max-width: @content-width;
  }

  .top-contacts {
    flex-wrap: wrap;
    column-gap: 0;

    padding: 22px 10px;
  }

  .header__item-search .top-contacts {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-menu {
    padding-left: @menu-width + 25px;
  }

  .main-menu {
    padding-left: 20px;
  }

  .header-menu .ul-lvl-1 {
    justify-content: flex-start;

    padding: 10px 0;
  }

  .top-search {
    width: auto;
    flex-grow: 1;
  }

  .top-contacts__item {
    padding-top: 0;
    margin-bottom: 0;

    flex-shrink: 0;
  }

  .top-contacts__item:not(:last-child) {
    margin-right: 20px;
  }

  .top-contacts__item-tel {
    border-right: 1px solid #f6f6f6;

    padding-right: 20px;
  }

  .top-search form {
    justify-content: flex-end;
  }

  #ya-site-form0 {
    width: 386px;

    margin-right: 0;
    margin-left: auto !important;
  }

  .decor-title p {
    font-size: 32px;
  }

  .ul-col-2 .li-lvl-3:nth-child(odd) {
    border-right: 1px solid #e0e1e2;

    width: 45%;
    margin-right: 3%;
  }

  .contect-with-img__desc,
  .contect-with-img__noimg {
    padding: 20px 30px;
  }

  .contect-with-img__desc-montazh {
    padding: 20px 13px 10px;
  }

  .contacts-title,
  .top-contacts__item-callme,
  .top-contacts__item-email {
    display: block;
  }

  .top-contacts__contacts-link {
    display: none;
  }

  .top-contacts__item-callme {
    flex-grow: 1;

    text-align: right;
  }

  .contect-with-img__img,
  .contect-with-img__noimg {
    max-width: 50%;
  }

  .contect-with-img__desc-montazh .ul li {
    padding-left: 0;
  }

  .contect-with-img__desc-montazh .ul li b {
    padding-left: 30px;
  }

  .f-light_blue {
    font-size: 18px;
    margin-bottom: 35px;
  }

  .good-media__item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .search__item .input-search {
    max-width: 100px;
  }
}

@media (min-width: 1200px) {
  .lg-block {
    display: block;
  }
  td.lg-block {
    display: table-cell;
  }

  table {
    font-size: 16px;
  }

  .top-block {
    left: ~'calc(50% - 640px)';
  }

  .table thead tr td,
  .table thead tr th {
    padding: 17px 13px;
  }

  .elem-list_listing .elem-list_item-img {
    width: 270px;
  }

  .contect-with-img__img,
  .contect-with-img__noimg {
    max-width: 100%;
  }

  .contect-with-img__img-obsluzh {
    margin-right: 74px;
  }

  .mr-b-before-ul {
    margin-bottom: 5px;
  }
}

.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.h-pt5 {
  padding-top: 5px;
}

.h-pb5 {
  padding-bottom: 5px;
}

.h-pt15 {
  padding-top: 15px;
}

.h-pb12 {
  padding-bottom: 12px !important;
}

.h-pb15 {
  padding-bottom: 15px;
}

.h-pb50 {
  padding-bottom: 50px;
}

.view {
  margin-top: 20px;
  padding-bottom: 0px;

  display: flex;
  align-items: center;

  button {
    width: 28px;
    height: 28px;
    margin-left: 10px;
    background-image: url('/images/view-sprite.png');
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 0;
  }

  .view__plitka {
    &.active,
    &:hover,
    &:focus {
      background-position: 0 -34px;
      outline: none;
    }
  }

  &__listing {
    background-position: -34px 0;

    &.active,
    &:hover,
    &:focus {
      background-position: -34px -34px;
      outline: none;
    }
  }

  &__table {
    background-position: -68px 0;

    &.active,
    &:hover,
    &:focus {
      background-position: -68px -34px;
      outline: none;
    }
  }
}

@media (min-width: 500px) {
  .view {
    margin-top: 0;
  }
}

.goods-table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  background-color: #fff;

  margin-bottom: -1px;

  th {
    background: #0067c3;
    border-left: 1px solid white;

    font-size: 12px;
    font-weight: normal;
    color: #fff;

    padding: 10px 2px;
  }

  th:first-child {
    border-left: none;
  }

  td {
    vertical-align: middle;
    border: 1px solid #b2b2b2;
    text-align: center;
    padding: 10px 5px;

    background-color: #fff;
  }

  .price-detail {
    text-align: center;
  }

  td:first-child p {
    padding-left: 0;
  }

  .elem-list_item-name {
    color: @body-text-color;
  }

  .checkbox {
    margin: 7px auto 0;

    display: inline-flex;
  }

  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label,
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    line-height: 1.8;
  }
}

.goods-table th,
.goods-table td {
  vertical-align: middle;

  flex-shrink: 0;
  width: 50%;

  @media (min-width: 551px) {
    width: 20%;
  }

  @media (min-width: 800px) {
    width: 14.5%;
  }

  @media (min-width: 1200px) {
    width: 9.6%;
  }
}

.goods-table th:last-child,
.goods-table td:last-child {
  width: 60%;

  @media (min-width: 551px) {
    width: 45%;
  }

  @media (min-width: 621px) {
    width: 35%;
  }

  @media (min-width: 800px) {
    width: 25%;
  }
}

.goods-table th:first-child,
.goods-table td:first-child {
  width: 40%;

  @media (min-width: 551px) {
    width: 35%;
  }

  @media (min-width: 621px) {
    width: 25%;
  }

  @media (min-width: 800px) {
    width: 17%;
  }
}

.goods-table th:nth-child(6),
.goods-table td:nth-child(6),
.goods-table th:nth-child(4),
.goods-table td:nth-child(4) {
  display: none;

  @media (min-width: 1200px) {
    display: table-cell;
  }
}

.goods-table th:nth-child(2),
.goods-table td:nth-child(2),
.goods-table th:nth-child(7),
.goods-table td:nth-child(7) {
  display: none;

  @media (min-width: 800px) {
    display: table-cell;
  }
}

.goods-table th:nth-child(3),
.goods-table td:nth-child(3) {
  display: none;

  @media (min-width: 621px) {
    display: table-cell;
    width: 14%;
  }
}

.goods-table th:nth-child(5),
.goods-table td:nth-child(5) {
  display: none;

  @media (min-width: 551px) {
    display: table-cell;
  }
}

.goods-table td {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

.goods-table tbody tr:nth-child(odd) td {
  background-color: #f6f6f6;
}

.goods-table td {
  border: none;
}

.goods-table td:not(:last-child) {
  border-right: 1px solid #d0d0d0;
}

.goods-table_shadow {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.info-above-catalog {
  margin-bottom: 15px;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.info-above-catalog select {
  margin-bottom: 0;

  @media (max-width: 350px) {
    width: 100%;
  }
}

form select {
  margin-bottom: 12px;
}

select {
  height: 36px;
  -webkit-appearance: none;
  background-image: url(/images/select-arrow.png);
  background-position: right 7px center;
  background-size: 15px;
  background-repeat: no-repeat;
  background-color: #fff;

  line-height: 1.3;
  padding: 0 30px 0 15px;
  border: 1px solid #ccc;

  text-indent: 0.01px;
  text-overflow: '';
  appearance: none !important;
  font-size: 16px;
}

select::-ms-expand {
  display: none;
}

.about-block {
  display: flex;
  justify-content: center;
  width: 100%;
  background-image: url(/images/about_astra_mob.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  @media (min-width: 700px) {
    flex-direction: row;
    background-size: 45%;
    background-image: url(/images/about_astra.jpg);
    justify-content: space-between;
    padding-top: 0px;
  }

  &_disc {
    display: none;
  }
  @media (min-width: 700px) {
    &_disc {
      background-color: #2389a0;
      text-align: center;
      position: relative;
      padding: 5px;
      display: block;
      &:after {
        content: '';
        border: 15px solid transparent;
        border-left: 25px solid #ed1c24;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
      &:before {
        content: '';
        border: 20px solid transparent;
        border-bottom: 12px solid #fff;
        border-left: 34px solid #fff;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      p {
        font-size: 20px;
        color: #fff;
        line-height: 0.9;
        font-weight: 400;
        margin-bottom: 0px;
        @media (min-width: 1280px) {
          font-size: 29px;
        }
      }
      span {
        font-size: 30px;
        @media (min-width: 1280px) {
          font-size: 51px;
        }
      }
    }
  }
  &_title {
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1260px) {
      padding: 24px;
      width: 50%;
    }
  }
  &_text {
    display: flex;
    background: hsla(0, 0%, 100%, 0.9);
    @media (min-width: 700px) {
      background: transparent;
    }
    @media (min-width: 1260px) {
      padding-left: 20px;
      width: 60%;
      padding-bottom: 5px;
    }
  }
  &_logo {
    padding: 5px;
    text-align: center;
    background: hsla(0, 0%, 100%, 0.8);
    max-width: 70%;
  }
  &_content {
    padding: 10px;
    p {
      font-size: 17px;
      text-align: center;
      margin-bottom: 0px;
      font-weight: 400;
      @media (min-width: 700px) {
        margin-bottom: 10px;
      }
      @media (min-width: 1260px) {
        white-space: nowrap;
        font-size: 24px;
      }
    }
  }
}
.shema-block {
  @media (min-width: 900px) {
    display: flex;
    justify-content: space-around;
  }
}
.contect-with-img__noimg-flex {
  @media (min-width: 900px) {
    max-width: 40%;
  }
}

.links {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 7px;

  a {
    text-align: left;
    position: relative;
    border-bottom: 1px solid #e81542;
    color: #000;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    &:after {
      content: '';
      background-image: url(/images/arrow-down-red.png);
      width: 9px;
      height: 9px;
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      left: 50%;
      bottom: -10px;
    }

    &:hover {
      text-decoration: none;

      border-bottom: 1px solid #c30a31;
    }

    &:hover::after {
      background-image: url(/images/arrow-down-red-hover.png);
    }
  }
  @media (min-width: 700px) {
    flex-direction: row;
    a {
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }
}

.flex__row--full {
  display: flex;
  flex-direction: column;

  & .flex__item--third {
    width: 100%;

    @media (min-width: 768px) {
      width: 30%;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.septiks {
  display: flex;
  flex-direction: column;
  padding: 15px;

  border: 1px solid #7d1833;

  &::before {
    content: '';
    align-self: center;
    width: 230px;
    height: 298px;

    background-image: url(/images/septiks_sprite.jpg);
    background-position: left top;
  }

  &:nth-of-type(2)::before {
    background-position: center top;
  }

  &:last-of-type::before {
    background-position: right top;
  }

  & > div {
    width: 100%;
  }

  @media (min-width: 668px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 50%;
    }
  }

  @media (min-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }
}

.schema-block--row {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: none;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.schema-block--row .scheme_container,
.schema-block--row .certificates {
  width: 100%;
}

.schema-block--row .scheme_container {
  & img {
    width: 30%;
    height: auto;
  }

  & .scheme__text {
    width: 68%;

    & .ol {
      margin-left: 17px;
    }
  }
  @media (min-width: 900px) {
    width: 65%;
  }
}

.schema-block--row .certificates {
  max-width: 357px;

  @media (min-width: 900px) {
    width: 30%;
  }
}

.schema-block--row .scheme {
  /* @media (min-width: 900px) {*/
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  text-align: left;
  /*}*/
}

.ol--letter {
  position: relative;
}

.ol--letter li {
  list-style: none;

  &::before {
    margin-right: 3px;
  }

  &:nth-of-type(1)::before {
    content: 'А.';
  }

  &:nth-of-type(2)::before {
    content: 'Б.';
  }

  &:nth-of-type(3)::before {
    content: 'В.';
  }

  &:nth-of-type(4)::before {
    content: 'Г.';
  }
}

.top-img-full {
  background-color: #81a76f;

  &::before {
    background-image: linear-gradient(
      270deg,
      transparent,
      #8fa788,
      transparent
    );
  }

  .main-slider {
    flex-wrap: wrap;
    padding: 5px 5px 35px;

    @media all and (min-width: 800px) {
      padding-top: 11px;
    }

    @media all and (min-width: 1050px) {
      padding-left: 11px;
      padding-right: 11px;
    }

    @media all and (min-width: 1220px) {
      align-items: stretch;
      justify-content: space-between;
    }

    &__special {
      width: 100%;
      margin-bottom: 7px;

      font-weight: 500;
      font-size: 25px;
      line-height: 1.31em;
      letter-spacing: 0.01em;
      color: #06520d;
      text-shadow: 2px 2px 0 #fefeff, 2px -2px 0 #fefeff, -2px 2px 0 #fefeff,
        -2px -2px 0 #fefeff, 2px 0px 0 #fefeff, 0px 2px 0 #fefeff,
        -2px 0px 0 #fefeff, 0px -2px 0 #fefeff, 2px 2px 2px rgba(206, 89, 55, 0);
      text-align: center;

      @media all and (min-width: 768px) {
        margin-bottom: 8px;

        font-size: 30px;
      }

      @media all and (min-width: 1220px) {
        margin-bottom: 10px;

        font-size: 48px;
      }
    }

    &__tit {
      margin-bottom: 7px;

      font-weight: 900;
      font-size: 17px;
      line-height: 1.33em;
      text-transform: uppercase;
      color: #ffffff;

      @media all and (min-width: 768px) {
        margin-bottom: 8px;

        font-size: 22px;
      }

      @media all and (min-width: 1220px) {
        margin-bottom: 10px;

        font-size: 30px;
      }

      &-small {
        margin-bottom: 7px;

        font-weight: bold;
        font-size: 16px;
        line-height: 1.36em;
        color: #ffffff;
        text-align: center;

        @media all and (min-width: 768px) {
          margin-bottom: 8px;

          font-size: 18px;
        }

        @media all and (min-width: 1220px) {
          margin-bottom: 10px;

          font-size: 22px;
        }
      }
    }

    &__text {
      margin-bottom: 7px;

      font-size: 15px;
      line-height: 1.15em;
      color: #171717;
      font-weight: normal;

      @media all and (min-width: 768px) {
        margin-bottom: 8px;

        font-size: 17px;
      }

      @media all and (min-width: 1220px) {
        margin-bottom: 10px;

        font-size: 26px;
      }
    }
  }

  .form__elem {
    margin-bottom: 7px;

    border-color: rgba(199, 207, 47, 0.4);

    @media all and (min-width: 768px) {
      margin-bottom: 8px;

      font-size: 14px;
    }

    @media all and (min-width: 1220px) {
      margin-bottom: 16px;

      font-size: 16px;
    }
  }

  .form__elem,
  .btn-default {
    max-width: 300px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    font-size: 12px;

    border-radius: 0;

    @media all and (min-width: 768px) {
      padding-top: 8px;
      padding-bottom: 8px;

      font-size: 15px;
    }

    @media all and (min-width: 1220px) {
      padding-top: 12px;
      padding-bottom: 12px;

      font-size: 18px;
    }
  }

  .btn-default {
    text-transform: uppercase;
    font-weight: bold;
  }

  form,
  .main-slider--left,
  .main-slider--right {
    width: 100%;
  }

  form {
    text-align: center;
  }

  .main-slider--left {
    @media all and (min-width: 1220px) {
      width: 60%;
    }
  }

  .main-slider--right {
    @media all and (min-width: 1220px) {
      width: 35%;
    }
  }
}

.j-main-slider {
  height: 340px;

  @media all and (min-width: 390px) {
    height: 305px;
  }

  @media all and (min-width: 430px) {
    height: 280px;
  }

  @media all and (min-width: 500px) {
    height: 250px;
  }

  @media all and (min-width: 768px) {
    height: 300px;
  }

  @media all and (min-width: 1220px) {
    height: 330px;
  }
}

.top-img {
  height: 100%;
}

.main-slider {
  height: 77%;

  @media all and (min-width: 500px) {
    height: 93%;
  }

  @media all and (min-width: 768px) {
    height: auto;
  }
}

.two-block-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &__item {
    margin-top: 10px;

    @media all and (min-width: 800px) {
      width: 48%;
    }
  }
}

.image-with-text {
  // display: flex;
  flex-direction: column;
  max-width: 100%;

  @media all and (min-width: 800px) {
    flex-direction: row;
  }

  &__image {
    flex-shrink: 0;
    max-width: 100%;
    order: 2;

    .certificates {
      max-width: 390px;
    }

    @media all and (min-width: 800px) {
      order: 1;
    }
  }

  &__text {
    flex-grow: 1;
    order: 1;

    @media all and (min-width: 800px) {
      // width: 60%;
      margin-left: 30px;
      order: 2;
    }
  }
}

.schema-block--row .scheme_container-100 {
  width: 100%;
}

.table-max_width_800 {
  max-width: 800px;
  margin: 0 auto;
}

.doc-list_under_images .doc-list__item {
  font-size: 0.87em;
}

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media all and (max-width: 799px) {
    justify-content: center;
  }

  @media all and (min-width: 800px) {
    justify-content: space-between;
  }

  &-half > .grid__item {
    @media all and (min-width: 800px) {
      width: 48%;
    }
  }

  &-third > .grid__item {
    @media all and (min-width: 800px) and (max-width: 1289px) {
      width: 48%;
    }

    @media all and (min-width: 1290px) {
      width: 32%;
    }
  }

  &-quater > .grid__item {
    @media all and (min-width: 600px) and (max-width: 899px) {
      width: 48%;
    }

    @media all and (min-width: 900px) {
      width: 19%;
    }
  }

  &-fifth > .grid__item {
    @media all and (max-width: 899px) {
      width: 48%;
    }

    @media all and (min-width: 900px) {
      width: 19%;
    }
  }

  & > .grid__item {
    margin-bottom: 1em;
  }
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.8em;

    &-half {
      @media all and (min-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-third {
      @media all and (min-width: 800px) and (max-width: 1289px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media all and (min-width: 1290px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-quater {
      @media all and (min-width: 600px) and (max-width: 899px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media all and (min-width: 900px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &-fifth {
      @media all and (max-width: 899px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media all and (min-width: 900px) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    & > .grid__item {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.flex__column {
  flex-direction: column;
}

.link-blue,
.link-blue-small {
  display: inline-block;

  font-weight: 500;
  line-height: 1.15em;
  color: #ffffff;
  text-decoration: underline !important;

  background-color: #0e4a85;

  &:hover {
    text-decoration: none !important;
  }
}

.link-blue {
  margin-bottom: 10px;
  padding: 9px 9px 8px;
}

.top-links {
  align-items: flex-start;

  padding: 19px 16px 9px;

  background-color: #fff;
}

@media all and (max-width: 899px) {
  .link-blue {
    font-size: 16px;
  }

  .link-blue-small {
    padding: 9px 15px;

    font-size: 14px;
  }

  .column-count-3 {
    column-count: 2;
  }

  .column-count-4 {
    column-count: 3;
  }

  .top-links {
    margin-bottom: 20px;
  }
}

@media all and (min-width: 900px) {
  .link-blue {
    font-size: 20px;
  }

  .link-blue-small {
    padding: 12px 15px;

    font-size: 16px;
  }

  .column-count-2 {
    column-count: 2;
  }

  .column-count-3 {
    column-count: 3;
  }

  .column-count-4 {
    column-count: 4;
  }

  .top-links {
    margin-bottom: 90px;

    .grid__item {
      max-width: 400px;
    }
  }
}

.hr {
  width: 81.1%;
  margin: 0 auto 14px;

  background-color: rgba(0, 0, 0, 0.12);
}

ul.column-count-3 li {
  margin-bottom: 10px;
}

a.f-black:hover {
  text-decoration: underline !important;
}

.mod-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -6px -6px 10px;

  list-style: none;

  li {
    margin: 6px;
  }

  a {
    position: relative;
    padding-left: 29px;
    width: 100%;

    &::before {
      position: absolute;
      left: 15px;
      top: 50%;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
      transform: translate(0, -50%);
    }
  }
}

@supports (display: grid) {
  .mod-links {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12.5em, 1fr));
    justify-content: start;
    align-items: stretch;
    grid-gap: 10px;
    margin: 0 0 10px;

    li {
      margin: 0;
    }

    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  /*@media all and (max-width: 899px) {
		.mod-links {

		}
	}

	@media all and (min-width: 900px) {
		.mod-links {
			grid-gap: 10px;
		}
	}*/
}

.container_video {
  max-width: 450px !important;
  margin: 20px auto;
}
@media (min-width: 992px) {
  .container_video {
    margin: 0 auto;
    max-width: 900px !important;
  }

  ul.j-bxslider-video li iframe {
    max-width: 855px;
    height: 475px;
    margin-top: 15px;
    margin-left: 8px !important;
  }
}
.video {
  position: relative;
  width: 100%;
  height: 250px;
  background-color: #000000;

  @media (min-width: 992px) {
    height: 450px;
  }

  @media (min-width: 1100px) {
    height: 500px;
  }
}

.video__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: none;
  padding: 0;
  width: 68px;
  height: 48px;
  border: none;
  background-color: transparent;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.video__button-shape {
  fill: #212121;
  fill-opacity: 0.8;
}

.video__button-icon {
  fill: #ffffff;
}

.video__button:focus {
  outline: none;
}

.video:hover .video__button-shape,
.video__button:focus .video__button-shape {
  fill: #ff0000;
  fill-opacity: 1;
}

/* Enabled */

.video--enabled {
  cursor: pointer;
}

.video--enabled .video__button {
  display: block;
}

.characteristics__title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-bottom: 20px;
}
.characteristics__flex-wrapper {
  display: flex;
  gap: 27px;

  &:not(:last-child) {
    margin: 0 0 50px 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.characteristics__column.image_column {
  flex: 0 1 30%;
}
.characteristics__column.content_column {
  flex: 0 1 70%;
}
.characteristics__text-wrapper {
  padding: 0 0 18px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.characteristics__buttons-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 18px;

  @media (max-width: 531px) {
    flex-direction: column;
  }
}
.characteristics__buttons-column {
  text-align: center;
}
.characteristics__buttons-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 8px;
}
.characteristics__buttons-price {
  // margin: 0 0 20px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
}
.char-red {
  color: rgba(232, 21, 66, 1);
}

.price-small {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
}

.characteristics__wrapper {
  margin-top: 30px;
}

.documentation-button {
  display: flex;
  justify-content: center;
  margin: 31px 0 0 0;
}

.documentation-link {
  background: #2289a0;
  padding: 15px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: #fff;
  max-width: 360px;
  p {
    margin-bottom: 0;
    margin-left: 5px;
  }
}

.title-uppercase {
  text-transform: uppercase;
}

.mod-button__wrapper {
  display: flex;
  @media (max-width: 525px) {
    flex-direction: column;
  }
}

.mod-link__wrapper {
  text-align: center;
  &:not(:last-child) {
    margin-right: 15px;
    @media (max-width: 525px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  a {
    background: #2289a0;
    color: #fff;
    padding: 15px 45px;
    display: block;
    max-width: 192px;
    border-radius: 6px;
    @media (max-width: 800px) {
      max-width: 260px;
    }
  }
}

.b-tooltip-icon {
  cursor: pointer;
  color: #0274a4;
  font-weight: bold;
  background-color: #ed1c24;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  font-size: 14px;
  line-height: 16px;
  width: 15px;
  margin-left: 5px;
}

span.b-tooltip-icon,
.h-tooltip-wrapper:hover span.b-tooltip-icon {
  color: #fff;
  font-weight: bold;
  background-color: #ed1c24;
  border: 0;
  border-radius: 10px;
  font-size: 12px;
  width: 14px;
  height: 14px;
  position: absolute;
  box-sizing: border-box;
  bottom: 50%;
}

.popup_tool {
  cursor: pointer !important;
}

.h-tooltip-wrapper {
  position: relative;
  text-align: center;
}

.contract-button__wrapper {
  display: flex;
  justify-content: center;
}

.contract-button__link {
  background: #ed1c24;
  color: #fff;
  text-transform: uppercase;
  padding: 16px 37px;
  border-radius: 6px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;

  @media (max-width: 392px) {
    padding: 11px 10px;

    font-size:  15px;
  }
}

.contect-with-img_m {
  padding: 25px 25px;
  display: flex;
  justify-content: center;
  margin-top: -35px;
}

.models-button__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 655px) {
    flex-direction: column;
    align-items: center;
  }
}

.models-link__wrapper {
  &:not(:last-child) {
    margin: 0 18px 0 0;
    @media (max-width: 655px) {
      margin: 0 0 10px 0;
    }
  }
}

.models-button__link {
  background: rgba(34, 137, 160, 1);
  color: #fff;
  padding: 12px 30px;
  border-radius: 6px;
  display: block;
  max-width: 210px;
}

.column-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: center;

  .column {
    width: 25%;
    padding: 0 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    @media (max-width: 1121px) {
      width: 50%;
    }
    @media (max-width: 727px) {
      width: 100%;
    }

    .column-content {
      .header-row {
        width: 100%;
        height: 57px;
        padding: 10px 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2289a0;
        font-family: Roboto;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
      }

      .price {
        padding-top: 12px;
        @media (max-width: 1121px) {
          justify-content: space-around;
        }

        .price-ul {
          li {
            text-align: right;
            list-style-type: none;
            padding-bottom: 7px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #e81542;
          }
        }
      }
    }
  }

  .column:last-child {
    border-right: none;
  }

  @media (max-width: 1121px) {
    .column:nth-child(2) {
      border-right: none;
    }
  }

  @media (max-width: 631px) {
    .column:first-child,
    .column:nth-child(3) {
      border-right: none;
    }
  }

  .column-2 {
    width: 33% !important;
    @media (max-width: 1121px) {
      width: 50% !important;
    }
    @media (max-width: 727px) {
      width: 100% !important;
    }
  }
}

.black-ul a {
  color: #000000 !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}

.black-ul {
  @media (max-width: 1024px) {
    width: 153px;
  }

  @media (max-width: 490px) {
    width: max-content;
  }
}

.block-bg {
  position: relative;
  background-image: url('/uploads/images/emerald-bg.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 490px) {
    background-image: url('');
  }

  .contect-with-img__desc {
    @media (max-width: 490px) {
      flex-direction: column-reverse;
    }
  }
}

.block-bg:before {
  content: '';
  position: absolute;
  left: -60px;
  top: -150px;
  bottom: 0;
  z-index: 0;
  width: 56vw;
  height: 550px;
  background: #ffffff;
  z-index: 0;
}

@media (min-width: 600px) {
  .block-bg:before {
    left: -100px;
    top: -150px;
    width: 55%;
    height: 550px;
    -webkit-transform: rotate(-55deg);
    transform: rotate(-55deg);
  }
}

@media (min-width: 600px) {
  .block-bg:before {
    left: -10px;
    top: -180px;
    width: 463px;
    height: 1000px;
  }
}

@media (min-width: 1024px) {
  .block-bg:before {
    left: 140px;
    top: -180px;
    width: 463px;
    height: 1000px;
  }
}

@media (max-width: 490px) {
  .block-bg:before {
    background-color: transparent;
  }
}

.block-center-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 490px) {
    color: #2289a0;
  }

  p:first-child {
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    @media (max-width: 1300px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    @media (max-width: 780px) {
      margin-bottom: 0;

      font-size: 14px;
    }
  }

  p:last-child {
    font-size: 30px;
    line-height: 35px;
    @media (max-width: 1300px) {
      font-size: 24px;
    }
    @media (max-width: 1100px) {
      font-size: 20px;
    }
    @media (max-width: 865px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 680px) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      width: 180px;
    }
  }
}

.white-table {
  thead td {
    text-align: center !important;
  }
  tbody td {
    background-color: #fff !important;
    border-top: 1px solid #e6e7e8 !important;
    border-bottom: 1px solid #e6e7e8 !important;
  }
}

.gap-10 {
  gap: 10px;
}

.btn-narrow {
  padding-left: 12px !important;
  padding-right: 12px !important;
  font-size: 13px !important;
}

.align-left {
  text-align: left !important;
}

.instruction__row {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.instruction__column-image {
  @media (max-width: 768px) {
    order: 1;
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
  }
}

.instruction__column-content {
  @media (max-width: 768px) {
    order: 2;
  }
}

.instruction__column-title {
  padding: 10px 20px;
  margin: 0 0 12px 0;
  p {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    font-weight: 500;
  }
}

.instruction__column-title-dis {
  background: #ed1c24;
}

.instruction__column-title-all {
  background: #2289a0;
}

.instruction__column-list {
  margin: 0 0 12px 0;
  li {
    list-style-type: none;
    padding: 0 0 0 20px;
    margin: 0 0 10px 0;
  }
}

.instruction__column-list-dis {
  li {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ed1c24;
      left: 0;
      top: 5px;
    }
  }
}

.instruction__column-list-all {
  margin: 10px 0 12px 0;
  li {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #2289a0;
      left: 0;
      top: 5px;
    }
  }
}

.instruction__links-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.18);
  padding: 15px 0;
  display: flex;
  @media (max-width: 850px) {
    flex-direction: column;
  }
}

.instaruction__link-item {
  margin: 0 40px 0 0;
}

.instruction__link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-decoration: underline;
  color: #000000;
  position: relative;
  padding: 0 0 0 20px;
  &:hover {
    text-decoration: none;
  }
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2289a0;
    left: 0;
    top: 5px;
  }
}

.instruction__row_mod {
  margin: 0 0 12px 0;
  .instruction__column-image {
    flex: 0 1 40%;
  }
  .instruction__column-content {
    flex: 0 1 60%;
  }
}

.with-border {
  border-top: 1px solid rgba(0, 0, 0, 0.18);
  padding: 10px 0 0 0;
}

.bottom-link__wrapper {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1010px) {
    flex-direction: column;
  }
}

.bottom-link__item {
  margin: 0 50px 0 0;
  display: flex;
  flex-direction: column;
}

.bottom-link__link {
  font-weight: 500;
  text-decoration: underline;
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 0 10px 0;
  &:hover {
    text-decoration: none;
  }
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2289a0;
    left: 0;
    top: 5px;
  }
}

.bg-gray {
  background-color: #e6e7e8 !important;
}

.m-w380 {
  max-width: 380px !important;
}

.white-title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 15.5px;
}

.logo-subtitle-mobile {
  margin-left: 3px;
  font-size: 9px;
}

.link-t-decor-none {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.t-decor-none {
  text-decoration: none !important;
}

.blue-descr {
  position: absolute;
  bottom: 0;
  background: #0E4A85;
  width: 100%;
  padding: 16px 20px 15px;
  font-size: 18px;
  text-decoration-line: underline !important;
  color: #FFFFFF;

  &:hover {
    text-decoration-line: none !important;
  }
}

.h-w48-desc {
  width: 100%;

  @media all and (min-width: 800px) {
      width: 48%;
  }
}

.f-20 {
  font-size: 20px !important;
  line-height: 23px;
}

.bg-green {
  background-color: #2c8559;
  color: #fff;
  margin-bottom: 0;
  padding: 14px 30px;
  &a:hover {
      text-decoration: underline;
  }
}

.bg-red {
  background-color: #ed1c24;
  color: #fff;
  margin-bottom: 0;
  padding: 14px 30px;
  &a:hover {
      text-decoration: underline;
  }
}

.ul-green li::before {
  background-color: #2C8559;
}

.ul-red li::before {
  background-color: #E81542;
}

.file__wrapper {
  &:not(:first-child) {
    margin: 20px 0 0 0;
  }
  .bottom-link__link {
    padding: 0;
    &:before {
      display: none;
    }
  }
}

.file__bottom-wrapper {
  display: flex;
  justify-content: flex-end;
  .bottom-link__link {
    padding: 0;
    &:before {
      display: none;
    }
  }
}

.schemes {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media(max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__wrapper_three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 25px;
    @media(max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    .doc-list__item {
      width: 100%!important;
    }
  }
}

.plumb-info {
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 24px 0 0 0;

  @media(max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media(max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0;
  }

  @media(max-width: 530px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    img {

    }
  }

  &__text {
    p {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 0;
    }

    span {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

.mod-button__wrapper_m {
  margin: 5px 0 0 0;
  padding: 15px 0 0 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.footer {

  &__row-astra {
    font-family: 'Roboto';
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 100px;

    @media(max-width: 1170px) {
      grid-template-columns: auto auto;
      row-gap: 30px;
    }

    @media(max-width: 640px) {
      grid-template-columns: auto;
      row-gap: 30px;
    }
  }

  &__column-astra {

  }

  &__links-list {
    list-style: none;
    column-count: 3;

    li {
      &:not(:last-child) {
        margin: 0 0 4px 0;
      }
    }
  }

  &__title-astra {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 10px;
  }

  &__links-astra {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;

    &_col3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
    }

    &_contacts {
      row-gap: 4px;
    }
  }

  &__link-wrap-astra {

  }

  &__link-astra {
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;

    &_phone {
      position: relative;
      padding: 0 0 0 28px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(/uploads/images/footer-phone-call.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: 1px;
      } 
    }

    &_mail {
      position: relative;
      padding: 0 0 0 28px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(/uploads/images/footer-mail.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: 1px;
      } 
    }

    &_address {
      position: relative;
      padding: 0 0 0 28px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(/uploads/images/footer-marker.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: 1px;
      } 
    }

    &_work {
      position: relative;
      padding: 0 0 0 28px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(/uploads/images/footer-clock.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: 1px;
      } 
    }
  }

  &__service-astra{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    width: 220px;
  }

  &__service-link-wrap-astra {

  }

  &__service-link-astra{
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }
}

.quiz__button {
    left: auto!important;
    right: 0;
    bottom: 500px!important;
    transform: rotate(-90deg);
    transform-origin: 100% 100%;

    @media(max-width: 1280px) {
        font-size: 18px!important;
        padding: 14px!important;
    }

    @media(max-width: 1024px) {
        bottom: 60%!important;
    }
}

.background_blue {
  padding: 9px 16px 10px;
  background-color: rgba(34, 137, 160, 0.1);
}

.h-gap24 {
  gap: 24px;
}

.h-w70 {
  width: 70%;

  @media(max-width: 1253px) {
    width: 100%;
  }
}

.background_white {
  background-color: @white-color !important;
}

.table__border_grey {
  border: 1px solid #E6E7E8 !important;
}

.background_dark-blue {
  background-color: #2289A0 !important;
}

.h-w32 {
  width: 32%;

  @media(max-width: 500px) {
    width: 100%;
  }
}

.table_processing-volume td:before {
  text-align: right;
}

.table.table_processing-volume {
  thead {
    th {
      @media (min-width: 320px) and (max-width: 1010px) {
        width: 10%;
      }
      @media (min-width: 1011px) {
        &:first-child {
          width: 70%;
        }
        &:last-child {
          width: 30%;
        }
      }
    }
  }
  tr {
    display: flex;
    width: 100%;
    @media (min-width: 320px) and (max-width: 1010px) {
      display: block;
    }
    td {
      &.td-btns {
        @media (min-width: 320px) and (max-width: 1010px) {
          background-color: transparent !important;
          padding: 10px 5px;
          .f-center {
            width: 100%;
            button {
              width: 100%;
            }
          }
        }
      }
      &.full {
        &-hidden {
          display: none !important;
          @media (min-width: 320px) and (max-width: 1010px) {
            &::before {
              display: none;
            }
            display: flex !important;
          }
        }
      }
      &::before {
        width: unset;
        color: #000000;
        font-weight: normal;
      }
      &:first-child {
        width: 70%;
        @media (min-width: 320px) and (max-width: 1010px) {
          width: 100%;
          text-align: center;
          background: #F2F3F3;
          color: #000000;
        }
      }
      &:last-child {
        width: 30%;
        @media (min-width: 320px) and (max-width: 1010px) {
          width: 100%;
          flex-direction: row-reverse !important;
          justify-content: space-around !important;  
          color: #000000;  
        }
      }
      &.full {
        width: 100%;
      }
    }
  }
}

.delivery-paid-section {
  padding: 30px 0;
  background: url(/images/new-bg_pay-delivery.jpg) no-repeat;
  background-size: cover;
  background-position: 50%;
  &__title {
    margin: 0;
    padding-bottom: 11px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #ed1c24;
    &:first-child {
      width: 55%;
      border-right: 1px solid rgba(0,0,0,.2);
    }
    &:last-child {
      margin-left: 10px;
      width: 47%;
    }
  }
  &__header {
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,.2);
    @media (max-width: 700px) {
      display: none;
    }
  }
  &__content {
    margin: 0 auto;
    padding: 30px;
    max-width: 934px;
    background: hsla(0,0%,100%,.8);
    backdrop-filter: blur(4px);
  }
  &__body {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
  &__col {
    padding-top: 20px;
    @media (max-width: 700px) {
      &::before {
        content: attr(data-name);
        margin-bottom: 20px;
        display: block;
        padding-bottom: 11px;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2;
        text-transform: uppercase;
        color: #ed1c24;
        border-bottom: 1px solid rgba(0,0,0,.2);
      }
    }
    &:first-child {
      padding-right: 65px;
      width: 53%;
      border-right: 1px solid rgba(0,0,0,.2);
      @media (max-width: 700px) {
        padding-top: 0;
        padding-right: 0;
        width: 100%;
        border-right: none;
      }
    }
    &:last-child {
      width: 47%;
      @media (max-width: 700px) {
        padding-left: 0;
        width: 100%;
      }
    }

  }
}