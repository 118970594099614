.video {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #000000;

  @media (max-width: 768px) {
    height: 200px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;

    object-fit: cover;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: none;
    padding: 0;
    width: 68px;
    height: 48px;
    border: none;
    background-color: transparent;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &-shape {
      fill: #212121;
      fill-opacity: 0.8;
    }

    &-icon {
      fill: #ffffff;
    }

    &:focus {
      outline: none;
    }
  }
}

.video:hover .video__button-shape,
.video__button:focus .video__button-shape {
  fill: #ff0000;
  fill-opacity: 1;
}

/* Enabled */

.video--enabled {
  cursor: pointer;
}

.video--enabled .video__button {
  display: block;
}
