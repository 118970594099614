@black-color: #000;
@white-color: #fff;
@link-color: #337f34;
@link-border-bottom-color: #95be9e;
@red-color: #e81542;
@bg-btn-color: #ffc400;
@font-color: #000;
@light-gray-color: #f4f7f9;
@gray-color: #cdcdcd;
@dark-gray-color: #4c4a4c;
@hr-color: #e0e1e2;
@body-text-color: #565656;
@line-color: #ddd;
@content-width: 1280px;
@menu-width: 265px;
@half-content: @content-width/2;
@padding-left: 50% - @half-content;
@size-of-checkbox: 25px;
@border-radius-search: .3em;

@greate-font: 300 14px / 1.3 Roboto, Arial, Helvetica, sans-serif;


.inherit-font {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}