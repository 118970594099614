@import "quiz-variables.less";

@keyframes gradient {
    from {
        background-size: 100%;
    }

    20% {
        background-size: 200%;
    }

    30% {
        background-size: 400%;
    }

    40% {
        background-size: 800%;
    }

    50% {
        background-size: 1600%;
    }

    60% {
        background-size: 5000%;
    }

    70% {
        background-size: 800%;
    }

    80% {
        background-size: 200%;
    }

    /*90% {
        background-size: 200%;
    }*/

    to {
        background-size: 100%;
    }
}

.quiz {
    &__button {
        position: fixed;
        left: 0;
        bottom: 15%;

        display: inline-block;
        width: auto;
        padding: 17px 14px;

        color: #ffffff;
        text-transform: uppercase;
        font-family: arial, sans-serif;
        font-weight: 600;
        font-size: 22px;

        border: none;
        background-color: rgba(197, 3, 67, 0.78);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        animation: gradient 1.8s infinite linear;

        z-index: 1500;
    }
}

.fancybox-wrap--spec .fancybox-inner {
    overflow: visible !important;;

    background: transparent;
}

.quiz {
    position: relative;

    display: flex;
    flex-direction: column;
    min-height: 90vh;
    max-height: 90vh;
    width: 1115px;
    padding: 15px 20px 65px 20px !important;
    margin-top: 10px;
    margin-right: 10px;
    box-sizing: border-box;

    font-size: 16px;

    background-color: @quiz-background;
    box-shadow: 0px 3px 5.82px 0.18px rgba(78, 77, 77, 0.28);
    border-radius: 5px;
    border: 1px solid rgb(225, 225, 225);
}

.quiz-wrapper {
    flex-grow: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding-right: 250px;
}



.quiz-container {
    overflow-y: hidden;
    max-height: 290px;

    @media all and (min-height: 730px) {
        max-height: 390px;
    }

    @media all and (min-height: 810px) {
        max-height: 450px;
    }

    @media all and (min-width: 768px) {
        max-height: none;
    }
}

.quiz .f-red {
    color: #83081b;
}

.quiz__title {
    position: relative;

    padding-right: 270px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 20px;

    font-family: "Trebuchet MS", Tahoma, Arial, Helvetica, sans-serif;
    font-size: 20px;
    text-align: left;
    color: @quiz-title-color;
}

.quiz__title::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 4px;

    background-color: #008FCA;
}

.quiz__tag {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 9;
    &-wrapp{
        padding: 18px 11px;
        backdrop-filter: blur(1.5px) drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.2));
        border-radius: 6px;

    }
}

.quiz-tag {
    width: 275px;
    
    box-sizing: border-box;

    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    color: #ffffff;

    background: url('/images/quiz/astra/quize_side_bg.jpg') center center / cover no-repeat;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.16);


    &__p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    &__title{  
        padding: 10px 10% 0;

        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        
        color: #FFFFFF;
    }

    &__attention {
        padding-bottom: 0;
        
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: @quiz-attention-color;
    }

    &__discount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 92px;
        height: 92px;
        padding: 0;
        margin: 5px auto 7px;
    
        font-size: 34px;
        line-height: 46px;
        font-weight: bold;
        line-height: 1;
        color: @quiz-sale-color;
        background-repeat: no-repeat;
        background-image: url('/images/quiz/eurobion/quiz-discoun.png') ;
    }
}

.quiz_tel {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    color: #C50343;
}


.quiz__body {
    padding-right: 20px;
    padding-left: 10px;
}

.quiz__subtitle {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    color: @quiz-subtitle-color;
    text-align: left;
}

.big-radios {
    display: grid;
    grid-template-columns: repeat(2, 233px);
    grid-gap: 21px;
    margin-bottom: 20px;
}

.big-radios--col-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.big-radios--row-4 {
    grid-template-rows: repeat(4, 80px);
}

.big-radios--col-3 {
    grid-template-columns: repeat(3, 200px);
}

.big-radios--col-4 {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10px;
}

@media(max-width: 1320px) {
    .big-radios {
        grid-template-columns: repeat(2, 233px);
    }
    .big-radios--col-3 {
        grid-template-columns: repeat(3, 170px);
    }
    .big-radios--row-4 {
        grid-template-columns: repeat(3, 1fr);

    }
    .big-radios--col-4 {
        grid-template-columns: repeat(4, 1fr);
    }
}
    
@media(max-width: 1024px) {
    .big-radios {
        grid-template-columns: repeat(2, 170px);
    }
    .big-radios--col-3 {
        grid-template-columns: repeat(3, 170px);
    }
    .big-radios--row-4 {
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(4, 50px);

    }
    .big-radios--col-4 {
        grid-template-columns: repeat(4, 1fr);
    }
}

.big-radio {
    position: relative;

    display: flex;
    flex-direction: column;

    box-shadow: 0px 1px 8.91px 0.09px rgba(78, 77, 77, 0.3);
}

.big-radio img {
    width: 100%;
}

.big-radio__label {
    position: relative;

    flex-grow: 1;
    /*min-height: 55px;
	padding: 15px 10px 10px 45px;
	box-sizing: border-box;*/
    padding: 14px 5px 14px 45px !important;

    font-size: 16px;
    line-height: 1;
    text-align: left;

    border-radius: 0 0 5px 5px;
    background-color: #ffffff;

    cursor: pointer;
}

.big-radio__label span {
    font-size: 13px;
    color: #686868;
}

.big-radio__label::before {
    width: 24px !important;
    height: 24px !important;

    border: 1px solid #000000 !important;
    box-shadow: inset 0px 1px 2px 0px rgba(210, 210, 210, 0.55);
}

.big-radio__label {

    &::before,
    &::after {
        left: 12px !important;
        top: 50% !important;

        display: block;

        transform: translate(0, -50%);
    }
}

.big-radio__label:hover::before,
.big-radio__radio:checked + .big-radio__label::before {
    border-color: rgb(131, 8, 27);
}

.big-radio__radio:checked + .big-radio__label::after {
    /*content: '';
	position: absolute;
	top: 22px;
	left: 18px;
	
	display: block;
	width: 13px;
	height: 8px;

	border-left: 2px solid rgb(131, 8, 27);
	border-bottom: 2px solid rgb(131, 8, 27);
*/
    transform: translate(5px, -21px) rotate(35deg);
}

.big-radio__radio {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    flex-shrink: 0;

    visibility: hidden;
}

.big-radio__label-img {
    text-align: center;

    cursor: pointer;
}

.big-radios--col-4 .big-radio__label {
    min-height: 40px;
    padding: 5px 10px;
    padding-left: 40px;

    border-radius: 0;
}

.big-radios--col-4 .big-radio__label::before {
    top: 7px;
    left: 8px;
}

.big-radios--col-4 .big-radio__radio:checked + .big-radio__label::after {
    top: 13px;
    left: 14px;
}

.quiz .input {
    display: inline-block;
    width: 70px;
    padding: 7px;

    border: 1px solid #cccccc !important;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: inset 0px 1px 2px 0px rgba(222, 175, 182, 0.55);
}

.quiz .input {
    width: 150px;
    &.w-48 {
        width: 48% !important;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
}

.quiz__finish-title {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
}

.info-inputs {
    width: 100%;
    max-width: 560px;
    padding: 15px 40px;
    margin: 0 auto 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(max-width: 800px) {
        height: 30vh;
        overflow: auto;
    }
}

.info-inputs .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;

    border: none;
    box-shadow: none;
}


.btn-attention {
    width: 100%;
    padding: 20px;

    text-align: center;
    color: #ffffff;

    border-radius: 15px;
    border: none;
    background-color: @quiz-sale-color;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
}

.info-inputs .btn-attention {
    width: 100%;

    font-size: 22px;
}

.quiz__footer {
    padding-left: 10px;
    margin-top: 10px;
}

.quiz__btns {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    align-items: baseline;
    // margin-bottom: 15px;
}

.quiz__btns a.bx-prev,
.quiz__btns a.bx-next {
    display: inline-block;
    width: auto;
    padding: 15px 30px;

    font-weight: 500;
    text-decoration: none;

    border-radius: 8px;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
}

.quiz__btns .bx-next {
    font-size: 18px;
    color: #FFFFFF;

    background-color: @quiz-next-color  ;

    cursor: pointer;
}

.quiz__btns .bx-next {
    padding-top: 0;
    margin-left: 25px;
}

.quiz__btns .bx-prev {
    font-size: 17px;
    color: #000000;

    background-color: @quiz-prev-color;

    cursor: pointer;
}

.quiz__btns .bx-prev {
    padding-top: 0;
}

.quiz__btns .bx-prev::before,
.quiz__btns .bx-next::after {
    content: '';

    display: inline-block;
    width: 10px;
    height: 10px;
    box-sizing: border-box;

    font-size: 1.5em;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-left-width: 1px;
    border-left-style: solid;
}

.quiz__btns .bx-next::after {
    margin-left: 7px;

    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;

    transform: rotate(-135deg);
}

.quiz__btns .bx-prev::before {
    margin-right: 7px;

    border-bottom-color: #000000;
    border-left-color: #000000;

    transform: rotate(45deg);
}

.quiz__steps {
    margin-right: 20px;

    font-size: 20px;
    font-weight: 700;
}

.slider-range {
    position: relative;

    display: flex;
    justify-content: space-between;
    width: 60%;
}

.slider-range::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;

    display: block;
    height: 2px;
    width: 100%;

    background-color: @quiz-progress-color;
}

.slider-range span {
    z-index: 9;

    display: inline-block;
    width: 20px;
    height: 20px;

    background-color: @quiz-background;
    border: 2px solid @quiz-progress-color;
    border-radius: 50%;
}

.slider-range .active {
    background-color: @quiz-progress-color;
}

.quiz__footer {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;

    display: flex;
    align-items: center;
}

.quiz__range {
    flex-grow: 1;

    line-height: 0;
}

.quiz-btn {
    position: fixed;
    top: 80%;
    left: 0;
    z-index: 99;
}

.call-quiz-btn {
    padding: 20px 40px;

    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;

    border-radius: 0 7px 7px 0;
    border: none;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 24, 0, 0.631);

    cursor: pointer;
}

.fancybox-inner--visible {
    overflow: visible !important;
}

.fancybox-wrap--spec .fancybox-close {
    top: 10px;
    right: -20px;
    width: 20px;
    height: 20px;

    background-image: url(/images/fancybox-close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.fancybox-wrap--spec .fancybox-close:hover {
    background-position: center;
}

.fancybox-wrap--spec .fancybox-outer {
    top: 5px;
}

jdiv {
    z-index: 7999 !important;
}

/*.quiz__body:last-of-type {
    @media all and (min-width: 800px) {
        padding-right: 200px;
    }
}*/

@media screen and (min-width: 800px) {
    .info-inputs .short-input {
        width: 48.5%;
    }
}

@media screen and (max-width: 1150px) {
    .quiz {
        width: 900px;

        font-size: 14px;
    }

    .quiz__subtitle {
        font-size: 20px;
    }

    .quiz-tag {
        width: 230px;
        padding: 10px 5px;

        font-size: 14px;
        font-weight: normal;
    }

    .quiz-tag__title {
        font-size: 18px;
    }

    .quiz-tag__attention {
        font-size: 16px !important;
    }

    .quiz-tag__discount {
        width: 70px;
        height: 70px;

        font-size: 30px;

        background-size: contain;
    }

    .quiz-wrapper,
    .quiz__title {
        padding-right: 205px;
    }

    .quiz__finish-title {
        font-size: 16px;
    }

    .info-inputs {
        padding: 15px;
    }

    .info-inputs .btn-attention {
        font-size: 16px;
    }

    .btn-attention {
        padding: 15px;
    }

    .big-radio__label {
        font-size: 14px;
    }

    .big-radios--col-3 {
        grid-gap: 10px;
    }
}

@media screen and (max-width: 900px) {
    .quiz {
        position: fixed;

        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        padding: 20px 5px 105px;
        margin: 0;

        border-radius: 0;
        border: 0;
    }

    .quiz-wrapper,
    .quiz__title {
        padding-right: 10px;
    }

    .big-radio__label-img {
        display: none;
    }

    .big-radios {
        grid-template-columns: 1fr;
        grid-gap: 15px;
        margin-bottom: 15px;
    }

    .big-radio__label,
    .big-radios--col-4 .big-radio__label {
        display: block;
        min-height: 40px;
        padding: 10px;
        padding-bottom: 5px;
        padding-left: 40px;

        border-radius: 5px;
    }

    .big-radio__label::before,
    .big-radios--col-4 .big-radio__label::before {
        top: 7px;
        left: 8px;
    }

    .big-radio__radio:checked + .big-radio__label::after {
        top: 13px;
        left: 14px;
    }

    .quiz__footer {
        flex-wrap: wrap;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 0px;
    }

    .quiz__btns {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
    }

    .quiz p {
        margin-bottom: 10px;
    }

    .fancybox-inner--visible {
        overflow: auto !important;
    }

    .quiz-tag {
        position: static;

        width: 100%;
        margin-bottom: 10px;
    }

    .quiz-tag p {
        margin-bottom: 0;
    }

    .quiz__subtitle {
        padding-bottom: 0;
    }

    .mob-hide {
        display: none;
    }

    .quiz-tag__discount {
        display: inline;

        vertical-align: middle;

        background-image: none;
    }

    .quiz-tag__attention {
        display: inline;
        vertical-align: middle;
    }

    .fancybox-wrap--spec .fancybox-close {
        top: 5px;
        right: 5px;

        display: block;
    }

    .fancybox-wrap--spec {
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 9999999999;

        width: 100% !important;
    }

    .info-inputs {
        margin-bottom: 5px;
    }

    .button-arenda {
        padding: 10px 0;
    }
}

@media (max-width: 768px) {
    .big-radios {
        height: 40vh;
        overflow-y: auto;
    }
}
@media (max-width: 425px) {
    .big-radios {
        height: 30vh;
    }
    .quiz__btns .bx-next, .quiz__btns .bx-prev {
        font-size: 14px;
    }
}
@media (max-width: 400px) {
    .big-radios, .info-inputs {
        height: 20vh;
    }
   
}

@media screen and (max-width: 768px) {
    .quiz__footer {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 570px) {
    .quiz {
        padding-bottom: 190px;
    }

    .quiz__footer {
        flex-direction: column;
    }

    .quiz__btns .bx-next {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .quiz__btns a.bx-prev,
    .quiz__btns a.bx-next {
        text-align: center;
    }

    .quiz__range {
        width: 100%;
        margin-top: 10px;
    }

    .call-quiz-btn {
        padding: 10px 20px;

        font-size: 14px;
        font-weight: normal;
    }

    .quiz__finish-title,
    .quiz__finish-form p {
        padding-bottom: 0;
    }
}

.big-input {
    display: flex;
    align-items: stretch;
    
    &__img {
        display: none;
        
        @media all and (min-width: 901px) {
            display: block;
            margin: 0 15px 0 0 !important;
        }
    }
    
    &__label {
        display: flex;
        
        @media all and (min-width: 901px) {
            flex-direction: column;
        }
    }
    
    &__text {
        margin-bottom: 4px;
        
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        text-align: left;

        @media all and (max-width: 900px) {
            margin-right: 10px;
        }
    }
    
    &__input {
        padding: 3px 9px;
        max-width: 76px;
        
        border: 1px solid #000000;
    }
    
    &--full .big-input__input {
        flex-grow: 1;
        max-width: 190px;
        height: 3em;
    }
}

.f-special {
    font-weight: bold;
    color: @quiz-title-color;
}

.quiz__finish-title,
.quiz__finish-form p {
    font-weight: 500;
    font-size: 16px;
    @media(min-width: 1300px) {
        font-size: 20px;
    }
}

.quiz .btn-default {
    height: 47px;

    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: none;
    color: #FFFFFF;
    background-color: #C50343 !important;
}



.input_wrap {
	position: relative;
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

.quiz input + label:before {
	content: url(/images/quiz/topas/calendar.svg);
	position: absolute;
	right: 22px;
    top: 6px;
}

.prev_form {
    right: 0;
    bottom: 0;
    position: absolute;
}