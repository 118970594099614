.mobile-top-nav{
	font-size: 14px;
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	font-size: 12px;
	z-index: 9999;
	color: white;
	background: url('/images/bg-sm.jpg') left top #385151;
}
@media (min-width: 760px){
	.mobile-top-nav {
		background-image: url('/images/bg-md.jpg');
	}
}
.mobile-top-nav .show-menu {
	float: left;
	height: 34px;
	padding-left: 10px;
	padding-top: 8px;
	cursor: pointer;
	text-decoration: none;
	color: white;
}
@media (min-width: 760px) {
	.mobile-top-nav .show-menu{
		padding-left: 15px;
	}
}
.mobile-top-nav .show-menu div {
	position: relative;
	float: left;
	margin-right: 5px;
}
.mobile-top-nav .show-menu div, 
.mobile-top-nav .show-menu div:before,
.mobile-top-nav .show-menu div:after {
	content: '';
	width: 25px;
	height: 4px;
	border-radius: 3px;
	background: #b2e921;
}
.mobile-top-nav .show-menu div:before {
	top: 7px;
}
.mobile-top-nav .show-menu div:after {
	top: 14px;
}
.mobile-top-nav .show-menu div:before, 
.mobile-top-nav .show-menu div:after {
	position: absolute;
}
.mobile-top-nav .show-menu span{
	font-size: 14px;
	color: white;
	line-height: 1.1;
}
.b-mobile_menu-content-open .show-menu div {
	background: url(/images/mobile-menu-sprite.svg) no-repeat 0px 0px;
	width: 19px;
	height: 19px;
	margin-right: 14px;
}
.b-mobile_menu-content-open .show-menu div:before,
.b-mobile_menu-content-open .show-menu div:after{
	display: none;
}
.mobile-top-nav .to-top {
	float: right;
	position: relative;
	padding: 7px 17px;
	margin-right: 10px;
	cursor: pointer;
	font-size: 12px;
}
@media (min-width: 760px){
	.mobile-top-nav .to-top{
		margin-right: 15px;
	}
}
.mobile-top-nav .to-top:after {
	position: absolute;
	content: "";
	background: url(/images/mobile-menu-sprite.svg) no-repeat -33px 1px;
	width: 13px;
	height: 9px;
	top: 12px;
	right: 0;
}
.mobile-top-nav .toggle-version{
	margin: 0 auto;
	width: 120px;
	text-align: center;
	padding: 10px 0;
}
#b-mobile_menu-content{
	display: none;
}
.b-mobile_menu-content-open {
	left: 0 !important;
}
.sidr-open .sidr-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 9;
}
.sidr-open .g-content {
	position: fixed;
}
.mobile-top-nav .logo{
	display: block;
	margin: 9px auto;
	width: 140px;
}
.sidr{
	position: fixed;
	width: 270px;
	top: 33px;
	bottom: 0;
	left: 0;
	background: #12392a;
	z-index: 10;
	overflow: auto;
}
.sidr-open .all-content{
	position: fixed;
}