@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Thin.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:  url('/fonts/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src:  url('/fonts/Roboto-Condensed.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src:  url('/fonts/Roboto-BoldCondensed.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src:  url('/fonts/Roboto-BoldCondensedItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src:  url('/fonts/Roboto-CondensedItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
